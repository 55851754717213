import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Avatar, Box, Button, Container, Grid, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Paper, Popover, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { Backdrop } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import ResyncIcon from '@material-ui/icons/Sync';

import AppContainer from '../Components/AppContainer';
import Loader from '../Components/Loader';
import MadeWithLove from '../Components/MadeWithLove';

import InternalApi from '../Utils/InternalApi';
import Networker from '../Utils/Networker';
import withStyle from '../style';

const DatasetActionButton = ({ buttonText, dialogTitle, dialogContent, onConfirm, buttonColor = "primary" }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Grid item>
      <Button variant="contained" color={buttonColor} onClick={() => setIsOpen(true)} style={{ margin: "0 8px" }}>
        {buttonText}
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => {
            setIsOpen(false);
            onConfirm();
          }} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'popover' : undefined;

  return { anchorEl, handleOpen, handleClose, isOpen, id };
};

export default function DataCleaning({match}) {
  const [socket, setSocket] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productNameFilter, setProductNameFilter] = useState('');
  const [dataset, setDataset] = useState(null);
  const [datasetId, setDatasetId] = useState(null);
  const [pullDate, setPullDate] = useState(null);
  const [productToClean, setProductToClean] = useState({});
  const [showProductList, setShowProductList] = useState(true); // New state to toggle product list visibility
  const [datasetExists, setDatasetExists] = useState(null); // null indicates not checked, true/false will indicate the result
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [filterQueries, setFilterQueries] = useState([
    { field: '', type: 'match', value: '', confirmed: false, indented: false }
  ]);
  const [orderQueries, setOrderQueries] = useState([
    { field: '', increasing: true, confirmed: false }
  ]);
  const [outlierQueries, setOutlierQueries] = useState([
    { field: '', value: 1, confirmed: false }
  ]);

  const [importedRulesConfigurations, setImportedRulesConfigurations] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const { anchorEl: infoAnchorEl, handleOpen: handleInfoOpen, handleClose: handleInfoClose, isOpen: isInfoPopoverOpen, id: infoPopoverId } = usePopover();

  const history = useHistory();

  const updateProgress = _.throttle((imported, total) => {
    const progressPercent = (imported / total) * 100;
    setProgress(progressPercent);
    console.log(`Import progress: ${imported}/${total}`);
  }, 750); // Executes at most once every 750ms

  const onMessage = (data, socket) => {
    // console.log('Message received from server:', data);
    // Check for import progress updates
    if (data.imported !== undefined && data.total !== undefined) {
      updateProgress(data.imported, data.total);
    }

    // Handle final completion message
    if (data.done) {
      if (data.ok) {
        console.log('Operation completed successfully', data.message || '');
        // Perform any additional actions needed on completion
      } else {
        console.error('Operation failed', data.error || 'Unknown error');
        // Handle the error in UI
      }
      // Potentially deactivate loading state here if it was set for the operation
      setLoading(false);
    }
  };

  useEffect(() => {
    const connectSocket = async () => {
      try {
        const ws = await InternalApi.connect(onMessage);
        setSocket(ws);
        console.log('Connected to socket:', ws);
      } catch (error) {
        console.error('Failed to connect to socket:', error);
      }
    };
    connectSocket();

    return () => {
      if (socket) {
        InternalApi.disconnect(socket);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInitiateCleaning = (datasetId) => {
    if (datasetId) {
      // Navigate using the updated datasetId from the response
      history.push({
        pathname: `/datacleaning/${datasetId}`,
        state: { productToClean, dataset }
      });
    } else {
        // Handle the case where datasetId is not available
        console.error('Dataset ID is not available.');
    }
  };

  const refresh = useCallback(() => {
    Networker.get({
      root: 'products',
      inner: 'admin',
      cache: true
    }).then(({body}) => {
      setProducts(body.sort((a,b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 0 : -1;
      }));
      setLoading(false);
    }).catch((err) => {
      console.error(err);
    })
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    fetchConfigurations();  // Fetch saved configurations when the component mounts
  }, [productToClean]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterChange = useCallback(_.debounce((val) => {
    setProductNameFilter(val);
    setShowProductList(true); // Show the product list again when typing in the filter
  }, 150), []);  

  // Function run when a product is clicked, uses WebSocket to check if a dataset exists
  const handleProductClick = (product) => async (e) => {
    setProductToClean(product);
    setShowProductList(false); // Hide the product list

    if (!socket) {
        console.error("WebSocket is not connected.");
        return;
    }

    try {
        const msg = {
          productId: product._id, // Use the product ID to find the dataset
        };

        // Send the message through the WebSocket and wait for the response
        const response = await InternalApi.request(socket, 'dataset/find', msg);

        // Log the response from the server
        console.log('Response:', response);

        if (response && response.result && response.ok) {
            const dataset = response.result;
            console.log('Dataset exists:', dataset);
            setDatasetExists(true);
            setDataset(dataset);
            setDatasetId(dataset._id); // Store datasetId when dataset exists
            setPullDate(dataset.pullDate); // Store pullDate

            // Parse and set the rules for filters, orders, and outliers
            if (dataset.rules) {
              const { filter, order, outlier } = dataset.rules;

              if (filter) setFilterQueries(parseFilterRules(filter));
              if (order) setOrderQueries(parseOrderRules(order));
              if (outlier) setOutlierQueries(parseOutlierRules(outlier));
            }
        } else {
            console.log('No dataset found');
            setDatasetExists(false);
            setDataset(null);
            setDatasetId(null); // Reset datasetId when dataset doesn't exist
            setPullDate(null); // Reset pullDate
        }
    } catch (error) {
        console.error('Error fetching dataset via WebSocket:', error);
        setDatasetExists(null); // Assume no dataset if there's an error
        setDataset(null);
        setDatasetId(null); // Reset datasetId
        setPullDate(null); // Reset pullDate
    }
  };

  const handleResetClick = () => {
    setIsDialogOpen(true);
  };

  const handleConfirmReset = () => {
    setIsDialogOpen(false);
    resetRules(productToClean)();  // Pass the product value to resetRules
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleConfigSelect = (productId) => async () => {
    // Grab the rules of the selected product, parse and set them
    try {
      const msg = {
        productId: productId, // Use the product ID to find the dataset
      };

      // Send the message through the WebSocket and wait for the response
      const response = await InternalApi.request(socket, 'dataset/find', msg);

      // Log the response from the server
      console.log('Response:', response);

      if (response && response.result && response.ok) {
          const dataset = response.result;
          console.log('Dataset exists:', dataset);
          setDataset(dataset);

          // Parse and set the rules for filters, orders, and outliers
          if (dataset.rules) {
            const { filter, order, outlier } = dataset.rules;

            if (filter) setFilterQueries(parseFilterRules(filter));
            if (order) setOrderQueries(parseOrderRules(order));
            if (outlier) setOutlierQueries(parseOutlierRules(outlier));
          }
      } else {
          console.log('No dataset found, cannot import rules');
      }
    } catch (error) {
        console.error('Error importing dataset rules via WebSocket:', error);
    }
    handleMenuClose();
  };

  const resetRules = (product) => async (e) => {
    // Run dataset/find again, grab the rules, parse and set them
    try {
        const msg = {
          productId: product._id, // Use the product ID to find the dataset
        };

        // Send the message through the WebSocket and wait for the response
        const response = await InternalApi.request(socket, 'dataset/find', msg);

        // Log the response from the server
        console.log('Response:', response);

        if (response && response.result && response.ok) {
            const dataset = response.result;
            console.log('Dataset exists:', dataset);
            setDataset(dataset);

            // Parse and set the rules for filters, orders, and outliers
            if (dataset.rules) {
              const { filter, order, outlier } = dataset.rules;

              if (filter) setFilterQueries(parseFilterRules(filter));
              if (order) setOrderQueries(parseOrderRules(order));
              if (outlier) setOutlierQueries(parseOutlierRules(outlier));
            }
        } else {
            console.log('No dataset found, cannot refetch rules');
        }
    } catch (error) {
        console.error('Error fetching dataset rules via WebSocket:', error);
    }
  };

  const parseFilterRules = (filterRules) => {
    const queries = [];
    filterRules.forEach((rule) => {
      const keys = Object.keys(rule);
      const subQueries = [];
      keys.forEach((key) => {
        const conditions = Object.keys(rule[key]);
        conditions.forEach((condition) => {
          subQueries.push({
            field: key,
            type: condition,
            value: rule[key][condition],
            confirmed: true,
            indented: subQueries.length > 0 // Set indented if it's not the first sub-query
          });
        });
      });
      queries.push(...subQueries);
    });
    return queries;
  };

  const parseOrderRules = (orderRules) => {
    return Object.keys(orderRules).map((key) => ({
      field: key,
      increasing: orderRules[key],
      confirmed: true,
    }));
  };

  const parseOutlierRules = (outlierRules) => {
    return Object.keys(outlierRules).map((key) => ({
      field: key,
      value: outlierRules[key],
      confirmed: true,
    }));
  };

  const handleAddAndCondition = (index) => {
    setFilterQueries((prevQueries) => {
      const updatedQueries = [...prevQueries];
      // Remove the checkmark (confirmation) from the original entry
      updatedQueries[index].confirmed = false;
      // Add a new indented entry after the current entry
      updatedQueries.splice(index + 1, 0, { field: '', type: 'match', value: '', confirmed: false, indented: true });
      return updatedQueries;
    });
  };

  const handleConfirmFilterQuery = (index) => {
    setFilterQueries(prevQueries => {
      const updatedQueries = [...prevQueries];
      const query = updatedQueries[index];

      // Parse the value to ensure it's the correct type
      const parsedValue = parseValue(query.value);
  
      // Validation logic to ensure no empty fields
      if (query.field && query.type && parsedValue !== '' && parsedValue !== undefined && parsedValue !== null && !isNaN(parsedValue)) {
        if (query.indented) {
          // Confirm the previous non-indented entry and the current indented entry
          const previousQuery = updatedQueries[index - 1];
          updatedQueries[index - 1] = { ...previousQuery, confirmed: true };
          updatedQueries[index] = { ...query, value: parsedValue, confirmed: true };
        } else {
          // Mark the non-indented query as confirmed
          updatedQueries[index] = { ...query, value: parsedValue, confirmed: true };
        }
      } else {
        alert("Please fill out all fields before confirming.");
        return prevQueries;  // Do not update if validation fails
      }
  
      console.log("Filter Queries Confirmed: ", updatedQueries);
      return updatedQueries;
    });
  };

  const handleEditFilterQuery = (index) => {
    setFilterQueries(prevQueries => {
      const updatedQueries = [...prevQueries];
      updatedQueries[index].confirmed = false;
      return updatedQueries;
    });
  };
  
  const handleConfirmOrderQuery = (index) => {
    setOrderQueries(prevQueries => {
      const updatedQueries = [...prevQueries];
      const query = updatedQueries[index];
  
      if (query.field) {
        updatedQueries[index] = { ...query, confirmed: true };
      } else {
        alert("Please fill out the field before confirming.");
      }
      
      console.log("Order Queries: ", updatedQueries);
      return updatedQueries;
    });
  };

  const handleEditOrderQuery = (index) => {
    setOrderQueries(prevQueries => {
      const updatedQueries = [...prevQueries];
      updatedQueries[index].confirmed = false;
      return updatedQueries;
    });
  };
  
  const handleConfirmOutlierQuery = (index) => {
    setOutlierQueries(prevQueries => {
      const updatedQueries = [...prevQueries];
      const query = updatedQueries[index];
  
      if (query.field) {
        updatedQueries[index] = { ...query, confirmed: true };
        console.log("Outlier Query Confirmed: ", updatedQueries[index]);
      } else {
        alert("Please fill out the field before confirming.");
      }
      
      console.log("Outlier Queries: ", updatedQueries);
      return updatedQueries;
    });
  };

  const handleEditOutlierQuery = (index) => {
    setOutlierQueries(prevQueries => {
      const updatedQueries = [...prevQueries];
      updatedQueries[index].confirmed = false;
      return updatedQueries;
    });
  };

  const handleOrderDirectionChange = (e, index) => {
    const newQueries = [...orderQueries];
    newQueries[index].increasing = e.target.value === 'increasing';
    setOrderQueries(newQueries);
  };

  const handleFilterFieldChange = (e, index) => {
    const newQueries = [...filterQueries];
    newQueries[index].field = e.target.value;
    setFilterQueries(newQueries);
  };
  
  const handleFilterTypeChange = (e, index) => {
    const newQueries = [...filterQueries];
    newQueries[index].type = e.target.value;
    setFilterQueries(newQueries);
  };
  
  const handleFilterValueChange = (e, index) => {
    const newQueries = [...filterQueries];
    newQueries[index].value = e.target.value;
    setFilterQueries(newQueries);
  };
  
  const handleOrderFieldChange = (e, index) => {
    const newQueries = [...orderQueries];
    newQueries[index].field = e.target.value;
    setOrderQueries(newQueries);
  };
  
  const handleOutlierFieldChange = (e, index) => {
    const newQueries = [...outlierQueries];
    newQueries[index].field = e.target.value;
    setOutlierQueries(newQueries);
  };
  
  const handleOutlierValueChange = (e, index) => {
    const newQueries = [...outlierQueries];
    newQueries[index].value = parseInt(e.target.value, 10);
    setOutlierQueries(newQueries);
  };
  
  // Adding and removing queries
  const addFilterQuery = () => {
    setFilterQueries([
      ...filterQueries,
      { field: '', type: 'match', value: '', confirmed: false, indented: false }
    ]);
  };

  const removeFilterQuery = (index) => {
    setFilterQueries(prevQueries => {
      const updatedQueries = [...prevQueries];

      // Check if the row being deleted is indented or not
      const isIndented = updatedQueries[index].indented;

      // If the row is indented, just remove it and return
      if (isIndented) {
        updatedQueries.splice(index, 1);
        return updatedQueries;
      }

      // If the row is not indented, check if the next row exists and is indented
      if (index < updatedQueries.length - 1 && updatedQueries[index + 1].indented) {
        // Promote the next row to non-indented
        updatedQueries[index + 1] = { ...updatedQueries[index + 1], indented: false };
      }

      // Remove the current row at the specified index
      updatedQueries.splice(index, 1);
  
      // Log the updated queries for debugging
      console.log("Filter Queries after deletion:", updatedQueries);
  
      return updatedQueries;
    });
  };
  
  const addOrderQuery = () => {
    setOrderQueries([
      ...orderQueries,
      { field: '', increasing: true, confirmed: false }
    ]);
  };
  
  const removeOrderQuery = (index) => {
    setOrderQueries(prevQueries => {
      const updatedQueries = [...prevQueries];
  
      // Remove the query at the specified index
      updatedQueries.splice(index, 1);
  
      // Log the updated queries for debugging
      console.log("Order Queries after deletion:", updatedQueries);
  
      return updatedQueries;
    });
  };
  
  const addOutlierQuery = () => {
    setOutlierQueries([
      ...outlierQueries,
      { field: '', value: 1, confirmed: false }
    ]);
  };
  
  const removeOutlierQuery = (index) => {
    setOutlierQueries(prevQueries => {
      const updatedQueries = [...prevQueries];
  
      // Remove the query at the specified index
      updatedQueries.splice(index, 1);
  
      // Log the updated queries for debugging
      console.log("Outlier Queries after deletion:", updatedQueries);
  
      return updatedQueries;
    });
  };

  const generateQueryObject = () => {
    const filter = [];
    for (let i = 0; i < filterQueries.length; i++) {
      const query = filterQueries[i];
      if (query.confirmed && !query.indented) {
        // Start a new filter object
        let filterObj = { [query.field]: { [query.type]: query.value } };
        
        // Process all indented entries chained to this query
        while (i + 1 < filterQueries.length && filterQueries[i + 1].indented && filterQueries[i + 1].confirmed) {
          const nextQuery = filterQueries[++i];
          filterObj = {
            ...filterObj,
            [nextQuery.field]: { [nextQuery.type]: nextQuery.value }
          };
        }

        // Convert the filterObj to array format (Object.entries) for MongoDB storage
        filter.push(Object.entries(filterObj).flat());
      }
    }

    // Order Queries - Convert to array format for MongoDB storage
    const order = Object.entries(
      orderQueries
        .filter(query => query.confirmed)  // Only include confirmed queries
        .reduce((acc, query) => {
          acc[query.field] = query.increasing;
          return acc;
        }, {})
    );
  
    // Outlier Queries - Convert to array format for MongoDB storage
    const outlier = Object.entries(
      outlierQueries
        .filter(query => query.confirmed)  // Only include confirmed queries
        .reduce((acc, query) => {
          acc[query.field] = query.value;
          return acc;
        }, {})
    );
  
    const queryObject = {
      filter,
      order,
      outlier,
    };
  
    console.log('Query Object:', queryObject);
    return queryObject;
  };
  
  // Utility function to parse value types
  const parseValue = (value) => {
    // Check if the value is already a boolean
    if (typeof value === 'boolean') return value;

    // Check if the value is a string representation of 'true' or 'false'
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;

    // If the value is not a boolean, try to parse it as a number
    if (!isNaN(value)) return parseFloat(value);

    // If it's neither a boolean nor a number, return it as is (string)
    return value;
  };

  const handleSaveConfiguration = async () => {
    const queryObject = generateQueryObject();  // Generate the query object from your current configuration
  
    try {
      const response = await Networker.patch({
        root: `dataset/${productToClean._id}/rules`,
        body: {
          rules: queryObject  // Pass the generated query object as the "rules" object in the request body
        }
      });

      // if (response && response.body) {
      //   // Update the dataset state with the new rules
      //   const updatedDataset = { ...dataset, rules: response.body.rules };
      //   setDataset(updatedDataset);
      // }
  
      if (response.body) {
        console.log('Configuration saved successfully:', response.body);
        setDataset(response.body); // Update the dataset with the new rules
        alert('Configuration saved successfully!');
      } else {
        console.error('Failed to save configuration:', response.error);
        alert('Failed to save configuration. Error: ' + response.error);
      }
    } catch (error) {
      console.error('Failed to save configuration:', error);
      alert('Failed to save configuration. Error: ' + error);
    }
  };

  // // Function run when a product is clicked, checks if a dataset exists using GET request
  // const handleProductClick = (product) => (e) => {
  //   setProductToClean(product);
  //   setShowProductList(false); // Hide the product list

  //   // Call the API to check for dataset existence
  //   Networker.get({
  //     root: `dataset/${product._id}`,
  //   }).then(response => {
  //     console.log('Response:', response);
  //     if (response && response.body && response.body._id) {
  //       console.log('Dataset exists:', response.body);
  //       setDatasetExists(true);
  //       setDatasetId(response.body._id); // Store datasetId when dataset exists
  //       setPullDate(response.body.pullDate); // Store pullDate
  //     } else {
  //       console.log('No dataset found');
  //       setDatasetExists(false);
  //       setDatasetId(null); // Reset datasetId when dataset doesn't exist
  //       setPullDate(null); // Reset pullDate
  //     }
  //   }).catch(error => {
  //     console.log(error);
  //     console.error('Error fetching dataset:', error);
  //     setDatasetExists(false); // Assume no dataset if there's an error
  //     setDatasetId(null); // Reset datasetId
  //     setPullDate(null); // Reset pullDate
  //   });
  // };

  const fetchConfigurations = async () => {
    try {
      const response = await Networker.get({
        root: `dataset/with-rules`,
      });
  
      if (response.body) {
        console.log('Configurations fetched successfully:', response.body);
        setImportedRulesConfigurations(response.body);  // Update the menu options with the fetched configurations
        // setDatasetProductsWithRules(response.body);  // Update the menu options with the fetched configurations
      } else {
        setImportedRulesConfigurations([]);  // Reset the menu options if no configurations are fetched
        console.error('Failed to fetch configurations:', response.error);
      }
    } catch (error) {
      setImportedRulesConfigurations([]);  // Reset the menu options if an error occurs
      console.error('Failed to fetch configurations:', error);
    }
  };

  // Creating a dataset using WebSocket
  const createDataset = async () => {
    if (!socket) {
        console.error("WebSocket is not connected.");
        return;
    }
    setLoading(true);

    try {
        const msg = {
          productId: productToClean._id,
        };
        console.log('Creating dataset for product:', msg);

        const response = await InternalApi.request(socket, 'dataset/create', msg);
        if (response && response.dataset) {
            const dataset = response.dataset;
            console.log('Dataset created:', dataset);
            setLoading(false);
            history.push({
                pathname: `/datacleaning/${dataset._id}`,
                state: { productToClean, dataset }
            });
        } else {
            console.error('Failed to create dataset:', response.error);
            setLoading(false);
        }
    } catch (error) {
        console.error('WebSocket error during dataset creation:', error);
        setLoading(false);
    }
  };

  // // Creating a dataset using POST request (NO LONGER WORKING DUE TO SOCKET IMPLEMENTATION)
  // const createDataset = async () => {
  //   setLoading(true);

  //   try {
  //     const response = await Networker.post({
  //       root: `dataset/${productToClean._id}`,
  //       body: {}
  //     });

  //     if (response.body) {
  //       console.log('Dataset created:', response.body);
  //       const datasetId = response.body._id;
  //       setLoading(false); // Deactivate the backdrop before navigating away
  //       history.push({
  //         pathname: `/datacleaning/${datasetId}`,
  //         state: { productToClean }
  //       });
  //     } else {
  //       console.error('Failed to create dataset:', response.error);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.error('Failed to create dataset:', error);
  //     setLoading(false);
  //   }
  // };

  // Function to refresh the dataset entries using WebSocket
  const handleRefreshDataset = async () => {
    setLoading(true); // Activate loading state

    if (!socket) {
        console.error("WebSocket is not connected.");
        setLoading(false);
        return;
    }

    if (!productToClean || !productToClean._id) {
        console.error('Product ID is not available.');
        setLoading(false); // Deactivate loading state
        return; // Exit the function if no product ID is provided
    }

    try {
        // Constructing the message for dataset update
        const msg = {
          productId: productToClean._id,
          pullDate: new Date() // Sending the current date as the new pullDate
        };

        // Send the message through the WebSocket and wait for the response
        const response = await InternalApi.request(socket, 'dataset/patch', msg);

        console.log('Response:', response);
        if (response && response.dataset) {
          const dataset = response.dataset;
          console.log('Dataset pullDate updated:', dataset.pullDate);
          setLoading(false); // Deactivate the backdrop before navigating away
          history.push({
              pathname: `/datacleaning/${dataset._id}`,
              state: { productToClean, dataset }
          });
        } else {
          console.error('Failed to update dataset:', response.error);
          setLoading(false);
        }
    } catch (error) {
        console.error('Failed to update dataset via WebSocket:', error);
        setLoading(false); // Deactivate the backdrop before navigating away
    }
  };

  // // Function to refresh the dataset entries using PATCH request
  // const handleRefreshDataset = async () => {
  //   setLoading(true); // Activate loading state
  
  //   if (!productToClean || !productToClean._id) {
  //     console.error('Product ID is not available.');
  //     setLoading(false); // Deactivate loading state
  //     return; // Exit the function if no product ID is provided
  //   }
  
  //   try {
  //     const response = await Networker.patch({
  //       root: `dataset/${productToClean._id}`,
  //       body: { pullDate: new Date() } // Sending the current date as the new pullDate
  //     });
  
  //     if (response.body && response.body._id) {
  //       console.log('Dataset pullDate updated:', response.body);
  //       setLoading(false); // Deactivate the backdrop before navigating away
  //       history.push({
  //         pathname: `/datacleaning/${datasetId}`,
  //         state: { productToClean }
  //       });        
  //     } else {
  //       console.error('Failed to update dataset:', response.error);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.error('Failed to update dataset:', error);
  //     setLoading(false); // Deactivate the backdrop before navigating away
  //   }
  // };

  const classes = withStyle();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppContainer classes={classes} title="Data Cleaning" match={match}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} >
            <Grid container spacing={1} >
              
              {/* Full-Width Filter Text Field */}
              <Grid item xs>
                <TextField
                  label="Filter"
                  defaultValue=""
                  onChange={(e) => handleFilterChange(e.target.value)}
                  fullWidth
                  autoFocus
                />
              </Grid>

              {/* How to Use Tooltip */}
              <Grid item>
                <Tooltip title="How to Use">
                  <IconButton onClick={handleInfoOpen}>
                    <InfoIcon style={{ fontSize: "2.0rem" }} color="primary" aria-describedby={infoPopoverId} />
                  </IconButton>
                </Tooltip>
                <Popover
                  id={infoPopoverId}
                  open={isInfoPopoverOpen}
                  anchorEl={infoAnchorEl}
                  onClose={handleInfoClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Box p={2}>
                    <Typography variant="h6" style={{ marginBottom: '0px' }} gutterBottom>How to Use</Typography>
                    <List style={{ paddingLeft: 0, fontSize: '0.875rem', alignItems: 'flex-start' }}>
                      {[
                        "Type in the product you would like to validate data for. Select the product.",
                        "If no dataset exists, click on 'Create Dataset To Clean'. If a dataset does exist, you have three options.",
                        "Configure rules in the 'Measurement Flagger Rules Configuration' section. Use the Cloud Icon to import an existing configuration or 'Add Query' to create a new configuration.",
                        "After adding a query, confirm it with the CheckMark Icon. In the Filter section, click on the & symbol to chain two queries with an AND conditional.",
                        "Once all queries are confirmed, click 'Save Configuration' to store the configuration to the dataset.",
                        "Edit confirmed queries using the Pencil Icon and reconfirm changes with the CheckMark Icon. Do not forget to click 'Save Configuration' to apply changes.",
                        "Discard unwanted queries using the Trash Icon and save changes with the 'Save Configuration' button.",
                        "To reset unsaved changes, refresh the page or click the Sync Icon to fetch the stored configuration from the database.",
                        "Once the configuration is saved, either click 'Start / Continue Cleaning Process' or select 'Refresh Dataset Entries' to update measurements up to the present date."
                      ].map((text, index) => (
                        <ListItem key={index} variant="body2" style={{
                          display: 'list-item',
                          listStyleType: 'decimal',
                          listStylePosition: 'outside',
                          marginLeft: '20px',
                          paddingLeft: '1px',
                          paddingBottom: '1px',
                        }}>
                          <Typography variant="body2" component="span">
                            {text}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Popover>
              </Grid>
            </Grid>
  
            {/* Left Side: Product Info and Actions */}
            <Grid item xs={12} md={4}>
              {/* Conditionally render the product list or selected product details */}
              {showProductList ? (
                <Paper>
                  <List>
                    {products.filter(({ name }) => name.toLowerCase().includes(productNameFilter.toLowerCase())).map((product) => (
                      <ListItem key={product._id} button divider dense onClick={handleProductClick(product)}>
                        <ListItemAvatar>
                          <Avatar alt={product.name.substr(0, 3)} src={product.iconURL} />
                        </ListItemAvatar>
                        <ListItemText primary={product.name} />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              ) : productToClean && (
                <div>
                  {/* Display selected product details */}
                  <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '8px' }}>
                    <Avatar
                      alt={productToClean.name.substr(0, 3)}
                      src={productToClean.iconURL}
                      style={{ width: 90, height: 90 }} // Adjust size as needed
                    />
                    <div>
                      <h3 style={{ margin: 0, fontSize: '1.5rem' }}>{productToClean.name}</h3>
                      <h3 style={{ margin: '5px 0 0 0', fontSize: '1.0rem' }}>{productToClean.productId}</h3>
                    </div>
                  </div>
  
                  {/* Dataset Action Buttons */}
                  <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                    {datasetExists === false ? (
                      <DatasetActionButton
                        buttonText="Create Dataset to Clean"
                        dialogTitle="Confirm Action"
                        dialogContent="Do you want to create a new dataset to clean for this product? This may take some time."
                        onConfirm={createDataset}
                        buttonColor="primary"
                      />
                    ) : datasetExists === true ? (
                      <>
                        <DatasetActionButton
                          buttonText="Start / Continue Cleaning Process"
                          dialogTitle="Confirm Action"
                          dialogContent="Do you want to start/continue the cleaning process for this product?"
                          onConfirm={() => handleInitiateCleaning(datasetId)}
                          buttonColor="secondary"
                        />
                        <DatasetActionButton
                          buttonText="Refresh Dataset Entries"
                          dialogTitle="Confirm Action"
                          dialogContent="Do you want to refresh the dataset entries for this product? This action will update the entries of the dataset. This may take some time."
                          onConfirm={handleRefreshDataset}
                          buttonColor="primary"
                        />
                        {pullDate && (
                          <Grid item xs={12} style={{ paddingLeft: '16px' }}>
                            <Typography variant="body2" color="textSecondary">
                              Last Updated: {new Date(pullDate).toLocaleString()}
                            </Typography>
                          </Grid>
                        )}
                      </>
                    ) : null}
                  </Grid>
                </div>
              )}
            </Grid>
  
            {/* Right Side: Measurement Flagger Rules Configuration */}
            {!showProductList && productToClean && (
              <Grid item xs={12} md={8}>
                <Grid container justifyContent="space-between" alignItems="flex-end">
                  <Grid item>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
                      Measurement Flagger Rules Configuration
                    </Typography>
                  </Grid>
                  <Grid item>            
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSaveConfiguration}  // Run generateQueryObject on click
                      style={{ transform: 'translateY(-5px)' }}
                    >
                      Save Configuration
                    </Button>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Restore Saved Configuration">
                      <IconButton onClick={handleResetClick}>
                        <ResyncIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Import Configuration">
                      <IconButton onClick={handleMenuClick}>
                        <CloudDownloadIcon /> {/* Load operation icon */}
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      {importedRulesConfigurations.map((config) => (
                        <MenuItem key={config.datasetId} onClick={handleConfigSelect(config.productId)}>
                          {config.productName}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Grid>
                </Grid>

                {/* Dialog for Restore to Saved Confirmation in DB */}
                <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                  <DialogTitle>Confirm Rules Configuration Resync</DialogTitle>
                  <DialogContent>
                    <DialogContentText>Are you sure you want to restore the rules configuration from the db? Any unsaved changes will be lost.</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="secondary">
                      Cancel
                    </Button>
                    <Button onClick={handleConfirmReset} color="primary" autoFocus>
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Filter Queries</Typography>
                    {(() => {
                      let numberingIndex = 1; // Initialize numbering index
                      return filterQueries.map((query, index) => {
                        const shouldShowNumber = !query.indented; // Only show number for non-indented queries

                        return (
                          <Grid
                            container
                            spacing={1}
                            key={index}
                            alignItems="center"
                            style={{
                              marginLeft: query.indented ? '20px' : '0',
                              backgroundColor: query.confirmed ? '#f0f0f0' : 'transparent',
                              borderRadius: '4px',
                              padding: '5px',
                              boxShadow: query.confirmed ? '0 2px 4px rgba(0,0,0,0.1)' : 'none',
                            }}
                          >
                            {shouldShowNumber && (
                              <Grid item xs={"auto"} style={{ paddingRight: '8px' }}>
                                <Typography>{numberingIndex++}.</Typography> {/* Increment numberingIndex only for non-indented rows */}
                              </Grid>
                            )}
                            <Grid item xs={5}>
                              <TextField
                                label="Field"
                                value={query.field}
                                onChange={(e) => handleFilterFieldChange(e, index)}
                                fullWidth
                                disabled={query.confirmed}
                              />
                            </Grid>
                            <Grid item xs={"auto"} style={{ margin: '0 8px' }}>
                              <Select
                                label="Type"
                                value={query.type}
                                onChange={(e) => handleFilterTypeChange(e, index)}
                                fullWidth
                                disabled={query.confirmed}
                              >
                                <MenuItem value="match">Match</MenuItem>
                                <MenuItem value="min">Min</MenuItem>
                                <MenuItem value="max">Max</MenuItem>
                                <MenuItem value="invert">Invert</MenuItem>
                              </Select>
                            </Grid>
                            <Grid item xs={"auto"} style={{ margin: '0 8px' }}>
                              <TextField
                                label="Value"
                                value={query.value}
                                onChange={(e) => handleFilterValueChange(e, index)}
                                fullWidth
                                disabled={query.confirmed}
                              />
                            </Grid>
                            <Grid>
                              {query.confirmed ? (
                                <IconButton onClick={() => handleEditFilterQuery(index)}>
                                  <EditIcon />
                                </IconButton>
                              ) : (
                                <IconButton onClick={() => handleConfirmFilterQuery(index)} disabled={!query.field}>
                                  <CheckIcon />
                                </IconButton>
                              )}
                            </Grid>
                            <Grid>
                              <IconButton onClick={() => removeFilterQuery(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                            {!query.indented && (
                              <Grid>
                                <IconButton onClick={() => handleAddAndCondition(index)}>
                                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>&</Typography>
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        );
                      });
                    })()}
                    <Button onClick={addFilterQuery} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                      Add Query
                    </Button>
                  </Grid>
  
                  <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Typography variant="h6">Order Queries</Typography>
                    {orderQueries.map((query, index) => (
                      <Grid
                        container
                        spacing={1}
                        key={index}
                        alignItems="center"
                        style={{
                          backgroundColor: query.confirmed ? '#f0f0f0' : 'transparent',
                          borderRadius: '4px',
                          padding: '5px',
                          boxShadow: query.confirmed ? '0 2px 4px rgba(0,0,0,0.1)' : 'none',
                        }}
                      >
                        <Grid item xs={"auto"} style={{ paddingRight: '8px' }}>
                          <Typography>{index + 1}.</Typography> {/* Number order queries based on index */}
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            label="Field"
                            value={query.field}
                            onChange={(e) => handleOrderFieldChange(e, index)}
                            fullWidth
                            disabled={query.confirmed}
                          />
                        </Grid>
                        <Grid item xs={"auto"} style={{ margin: '0 8px' }}>
                          <Select
                            label="Direction"
                            value={query.increasing ? 'increasing' : 'decreasing'}
                            onChange={(e) => handleOrderDirectionChange(e, index)}
                            // fullWidth
                            disabled={query.confirmed}
                          >
                            <MenuItem value="increasing">Increasing</MenuItem>
                            <MenuItem value="decreasing">Decreasing</MenuItem>
                          </Select>
                        </Grid>
                        <Grid>
                          {query.confirmed ? (
                            <IconButton onClick={() => handleEditOrderQuery(index)}>
                              <EditIcon />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => handleConfirmOrderQuery(index)} disabled={!query.field}>
                              <CheckIcon />
                            </IconButton>
                          )}
                        </Grid>
                        <Grid>
                          <IconButton onClick={() => removeOrderQuery(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                    <Button onClick={addOrderQuery} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                      Add Query
                    </Button>
                  </Grid>
  
                  <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Typography variant="h6">Outlier Queries</Typography>
                    {outlierQueries.map((query, index) => (
                      <Grid 
                        container 
                        spacing={1} 
                        key={index}
                        alignItems="center"
                        style={{
                          backgroundColor: query.confirmed ? '#f0f0f0' : 'transparent',
                          borderRadius: '4px',
                          padding: '5px',
                          boxShadow: query.confirmed ? '0 2px 4px rgba(0,0,0,0.1)' : 'none',
                        }}
                      >
                        <Grid item xs={"auto"} style={{ paddingRight: '8px' }}>
                          <Typography>{index + 1}.</Typography> {/* Number outlier queries based on index */}
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            label="Field"
                            value={query.field}
                            onChange={(e) => handleOutlierFieldChange(e, index)}
                            fullWidth
                            disabled={query.confirmed}
                          />
                        </Grid>
                        <Grid item xs={"auto"} style={{ margin: '0 8px' }}>
                          <Select
                            label="Value"
                            value={query.value}
                            onChange={(e) => handleOutlierValueChange(e, index)}
                            // fullWidth
                            disabled={query.confirmed}
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                          </Select>
                        </Grid>
                        <Grid>
                          {query.confirmed ? (
                            <IconButton onClick={() => handleEditOutlierQuery(index)}>
                              <EditIcon />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => handleConfirmOutlierQuery(index)} disabled={!query.field}>
                              <CheckIcon />
                            </IconButton>
                          )}
                        </Grid>
                        <Grid>
                          <IconButton onClick={() => removeOutlierQuery(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                    <Button onClick={addOutlierQuery} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                      Add Query
                    </Button>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSaveConfiguration}  // Run generateQueryObject on click
                  style={{ marginTop: '20px' }}
                >
                  Save Configuration
                </Button>
              </Grid>
            )}
          </Grid>
        </Container>
        <Backdrop open={loading} style={{ zIndex: theme => theme.zIndex.drawer + 1, color: '#fff' }}>
          <Loader show={loading} progress={progress} />
        </Backdrop>
        <MadeWithLove />
      </AppContainer>
    </div>
  );
}