import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import _ from 'lodash';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, ClickAwayListener, Backdrop } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Checkbox, IconButton, LinearProgress, List, ListItem, Menu, MenuItem, Popover, Snackbar, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WarningIcon from '@material-ui/icons/Warning';
import { ArrowDownward, ArrowUpward, Check } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import Loader from '../Components/Loader';
import MadeWithLove from '../Components/MadeWithLove';

import InternalApi from '../Utils/InternalApi';
import Networker from '../Utils/Networker';

// Constants for the DataGrid. Adjust as needed.
const ROW_HEIGHT = 52; // This is an example height per row.
const HEADER_HEIGHT = 56; // Height of the header row.
const MAX_ROWS = 100; // The maximum number of rows to display

const rowColors = {
  selectedInvalidatedRow: 'rgba(255, 140, 0, 0.4)',
  invalidatedRow: 'rgba(255, 165, 0, 0.2)',
  excludedFromMiddlewareRow: 'rgba(255, 99, 71, 0.2)',
  selectedExcludedFromMiddlewareRow: 'rgba(255, 99, 71, 0.4)',
  cleanedMacStatus: 'rgba(144, 238, 144, 0.6)',
  inProgressMacStatus: 'rgba(255, 165, 0, 0.4)',
  flaggedMacStatus: 'rgba(255, 99, 71, 0.6)',
  validatedRow: 'rgba(144, 238, 144, 0.6)',
  validatedMacStatus: 'rgba(135, 206, 250, 0.6)',
};

// Custom styles for the DataGrid rows based on their selection and validation status
const useStyles = makeStyles({
  selectedInvalidatedRow: {
    backgroundColor: 'rgba(255, 140, 0, 0.4) !important', // Darker orange for selected invalidated rows
    '&:hover': {
      backgroundColor: 'rgba(255, 140, 0, 0.6) !important', // Even darker on hover
    },
  },
  selectedRow: {
    backgroundColor: 'rgba(220, 220, 220, 0.8)', // Light grey for selected valid rows
    '&:hover': {
      backgroundColor: 'rgba(200, 200, 200, 0.8) !important', // Slightly darker grey on hover
    },
  },
  invalidatedRow: {
    backgroundColor: 'rgba(255, 165, 0, 0.2)', // Light orange for invalidated rows
    '&:hover': {
      backgroundColor: 'rgba(255, 165, 0, 0.4) !important', // Slightly darker on hover
    },
  },
  // Style for rows that are excluded from middleware re-run
  excludedFromMiddlewareRow: {
    textDecoration: 'line-through', // Strikethrough text
    backgroundColor: 'rgba(255, 99, 71, 0.2) !important', // Light red background
    '&:hover': {
      backgroundColor: 'rgba(255, 99, 71, 0.4) !important', // Slightly darker on hover
    },
  },
  // Style for selected rows that are excluded from middleware re-run
  selectedExcludedFromMiddlewareRow: {
    textDecoration: 'line-through', // Strikethrough text
    backgroundColor: 'rgba(255, 99, 71, 0.4) !important', // Light red background
    '&:hover': {
      backgroundColor: 'rgba(255, 99, 71, 0.6) !important', // Slightly darker on hover
    },
  },
  // Style for rows that are validated and selected
  selectedValidatedRow: {
    backgroundColor: 'rgba(144, 238, 144, 0.6) !important', // Light green background
    '&:hover': {
      backgroundColor: 'rgba(144, 238, 144, 0.8) !important', // Slightly darker on hover
    },
  },
  // Style for rows that are validated
  validatedRow: {
    backgroundColor: 'rgba(144, 238, 144, 0.3)', // Light green background
    '&:hover': {
      backgroundColor: 'rgba(144, 238, 144, 0.5) !important', // Slightly darker on hover
    },
  },
});

// Util function to flatten nested objects into a single level object (used to generate dynamic columns for the DataGrid)
const flattenObject = (obj, parentKey = '', separator = '.') => {
  let flatObject = {};

  for (const [key, value] of Object.entries(obj)) {
    const newKey = parentKey ? `${parentKey}${separator}${key}` : key;

    if (value && typeof value === 'object' && !Array.isArray(value)) {
      // Recursively flatten nested objects
      Object.assign(flatObject, flattenObject(value, newKey, separator));
    } else {
      // Assign value to the newKey in the flat object
      flatObject[newKey] = value;
    }
  }

  return flatObject;
};

// Custom header component for the DataGrid columns
const CustomHeader = ({ columnInfo }) => {
  return (
    <div style={{ 
      whiteSpace: 'normal', 
      lineHeight: '20px', 
      padding: '5px',
      overflow: 'auto',
      }}>
      {columnInfo.headerName}
    </div>
  );
};

// Custom tooltip component for the DataGrid
const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff', // White background
    color: 'rgba(0, 0, 0, 0.87)', // Black text
    boxShadow: theme.shadows[3], // Material-UI shadow for some depth
    fontSize: '14px',
    border: '1px solid #dadde9', // Gray border
    borderRadius: '8px', // Rounded corners
    padding: '10px', // Padding for better readability
    whiteSpace: 'pre-wrap', // Break words to new line if needed
  },
}))(Tooltip);

// Extended CustomPopover for wider content for survey responses
const WideCustomPopover = withStyles((theme) => ({
  paper: {
    backgroundColor: '#fff', // White background
    color: 'rgba(0, 0, 0, 0.87)', // Black text
    boxShadow: theme.shadows[3], // Material-UI shadow for some depth
    fontSize: 14,
    border: '1px solid #dadde9', // Gray border
    minWidth: '850px', // Set a minimum width to prevent the popover from being too narrow
    overflow: 'auto',
    padding: '-8px',
  },
}))(Popover);

function ClickablePopover({ title, iconColor }) {
  const [anchorEl, setAnchorEl] = useState(null);

  // Function to lighten the icon color when clicked
  const lightenColor = (color, amount) => {
    let [r, g, b, a] = color.match(/\d+/g); // Extract RGBA values
    a = Math.max(Math.min(1, parseFloat(a) + amount), 0).toFixed(2); // Adjust alpha only
    return `rgba(${r}, ${g}, ${b}, ${a})`; // Return modified color
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // Adjust iconColor based on click
  const currentIconColor = open ? lightenColor(iconColor, 0.3) : iconColor;

  return (
    <ClickAwayListener onClickAway={handlePopoverClose}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <InfoIcon onClick={handlePopoverOpen} style={{ cursor: 'pointer', color: currentIconColor }} />
        <WideCustomPopover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: { pointerEvents: 'auto' },
          }}
        >
          <pre>{title}</pre>
        </WideCustomPopover>
      </div>
    </ClickAwayListener>
  );
}

// Confirmation Dialog component for various actions
const ConfirmationDialog = ({ isOpen, onClose, onConfirm, title, content }) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary">
        Cancel
      </Button>
      <Button onClick={onConfirm} color="primary" autoFocus>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

// Custom hook for handling the Popover and Menu components
const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'popover' : undefined;

  return { anchorEl, handleOpen, handleClose, isOpen, id };
};

// Function to render a valid cell with a checkmark or a cross based on the value as well as a warning icon (with a popup description) if the entry is flagged by the "Run Automated Measurement Flagger" operation
const renderValidCell = (params, flaggedEntries, mac, criteriaColorMapRef) => {
  const criteriaString = flaggedEntries.current[mac] ? flaggedEntries.current[mac][params.row.id] : null;

  // Checkmark SVG for true values (copied from MUI DataGrid's boolean icon rendering)
  const checkmarkIcon = (
    <svg className="MuiSvgIcon-root MuiDataGrid-booleanCell MuiSvgIcon-fontSizeSmall" style={{ color: '#008000' }} focusable="false" viewBox="0 0 24 24" role="img" data-value="true">
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
    </svg>
  );

  // Cross SVG for false values (copied from MUI DataGrid's boolean icon rendering)
  const crossIcon = (
    <svg className="MuiSvgIcon-root MuiDataGrid-booleanCell MuiSvgIcon-fontSizeSmall" style={{ color: '#B22222' }} focusable="false" viewBox="0 0 24 24" role="img" data-value="false">
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    </svg>
  );

  // Function to generate a random bold color for each flagged criteria
  const generateRandomBoldColor = () => {
    const getRandomValue = () => Math.floor(Math.random() * 128) + 128; // Values between 128 and 255
    const r = getRandomValue();
    const g = getRandomValue();
    const b = getRandomValue();

    const toHex = (n) => n.toString(16).padStart(2, '0');

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  // Function to get or create a color for each flagged criteria
  const getOrCreateColor = (criteria, criteriaColorMapRef) => {
    if (criteriaColorMapRef.current[criteria]) {
      return criteriaColorMapRef.current[criteria];
    } else {
      const newColor = generateRandomBoldColor();
      criteriaColorMapRef.current[criteria] = newColor;
      return newColor; // Return the new color immediately
    }
  };

  // Get the color for the criteria if it exists or generate a new color
  const criteriaColor = criteriaString ? getOrCreateColor(criteriaString, criteriaColorMapRef) : '#000';

  // Parse criteria if it exists and is a string
  let criteria = [];
  if (criteriaString) {
    try {
      criteria = JSON.parse(criteriaString);
    } catch (e) {
      console.error('Error parsing criteria:', e);
    }
  }

  // Format criteria for display in tooltip
  const formattedCriteria = criteria.length > 0 ? criteria.map(criterion => JSON.stringify(criterion, null, 2)).join('\n') : '';

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {params.value ? checkmarkIcon : crossIcon}
      {formattedCriteria && (
        <CustomTooltip title={`Flagged criteria:\n${formattedCriteria}`} placement="right">
          <div style={{ marginLeft: '8px', display: 'flex' }}>
            <WarningIcon style={{ fontSize: '28px', color: criteriaColor }} />
          </div>
        </CustomTooltip>
      )}
    </div>
  );
};

// Component to render the survey responses cell with a tooltip and buttons to move the survey responses
const RenderSurveyResponsesCellTest = ( params, isValid, startMovingSurveyResponse, confirmMovingSurveyResponse, surveyMoveInProgressRef ) => {
  if (!params.value || params.value.length === 0) {
    return null; // Return null if there are no survey responses
  }

  // Assuming surveyResponses is directly accessible from params.row
  const surveyResponses = params.row.surveyResponses;

  if (!surveyResponses || surveyResponses.length === 0) {
    return null; // Return null if there are no survey responses
  }

  // Map over params.value to get each item's responses, then flatten the array
  // const responses = params.value.map(item => item.responses).flat();
  // const surveyResponsesString = JSON.stringify(responses, null, 2); // replace with params.value for full surveyResponses array
  const surveyResponsesString = JSON.stringify(params.value, null, 2); // replace with params.value for full surveyResponses array

  // Determine the icon color based on the row's validity to easily see what survey's need handled/moved
  const iconColor = !isValid ? 'rgba(255, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.87)'; // Red for invalid, black for valid

  // Update surveyMoveInProgressRef to track the current surveyMoveInProgress state
  const currentSurveyMoveInProgress = surveyMoveInProgressRef.current;
  // console.log('currentSurveyMoveInProgress:', currentSurveyMoveInProgress);

  // Check if any entry _id within this row is currently being moved
  const isSurveyBeingMoved = currentSurveyMoveInProgress.includes(params.row.id);
  // console.log('isSurveyBeingMoved:', isSurveyBeingMoved);

  return (
    <>
      <ClickablePopover title={surveyResponsesString} iconColor={iconColor} />
      {(isSurveyBeingMoved || !isValid) && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ArrowUpward onClick={() => startMovingSurveyResponse(params.row.id, 'up')} style={{ cursor: 'pointer' }} />
          <ArrowDownward onClick={() => startMovingSurveyResponse(params.row.id, 'down')} style={{ cursor: 'pointer' }} />
          <Check onClick={() => confirmMovingSurveyResponse(params.row.id)} style={{ cursor: 'pointer' }} />
        </div>
      )}
    </>
  );
};

const CriteriaColorKey = ({
  criteriaColorMapRef,
  rows,
  setSelectedRows,
  selectedRowsRef,
  currentMacIndex,
  macAddresses,
  flaggedEntriesRef
}) => {
  const [selectedCriteria, setSelectedCriteria] = useState([]);

  const handleCheckboxChange = (event) => {
    const criteria = event.target.name;
    const isChecked = event.target.checked;
  
    let updatedSelectedCriteria;
    if (isChecked) {
      // Add the criteria to the selected list
      updatedSelectedCriteria = [...selectedCriteria, criteria];
    } else {
      // Remove the criteria from the selected list
      updatedSelectedCriteria = selectedCriteria.filter(c => c !== criteria);
    }
    setSelectedCriteria(updatedSelectedCriteria);
  
    // Get the current MAC address
    const currentMacAddress = macAddresses[currentMacIndex];
  
    // Recalculate the matching rows based on the updated selected criteria
    const matchingRows = rows
      .filter((row) => {
        const flaggedEntriesForRow = flaggedEntriesRef.current[currentMacAddress]?.[row.id];
        return flaggedEntriesForRow && updatedSelectedCriteria.some(criteria => flaggedEntriesForRow.includes(criteria));
      })
      .map((row) => row.id);
  
    // Update selectedRows state with the recalculated matching row IDs
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = {
        ...prevSelectedRows,
        [currentMacAddress]: matchingRows,
      };
      selectedRowsRef.current = newSelectedRows;
      localStorage.setItem('selectedRows', JSON.stringify(newSelectedRows));
      return newSelectedRows;
    });
  };

  return (
    <Box padding="16px">
      <Typography variant="h6" gutterBottom>
        Flagged Entries Color Key
      </Typography>
      {Object.entries(criteriaColorMapRef.current).map(([criteria, color]) => (
        <Box display="flex" alignItems="center" marginBottom="8px" key={criteria}>
          <Checkbox
            name={criteria}
            checked={selectedCriteria.includes(criteria)}
            onChange={handleCheckboxChange}
            color="primary"
          />
          <WarningIcon style={{ color: color, marginRight: '8px' }} />
          <Typography variant="body2">{criteria}</Typography>
        </Box>
      ))}
    </Box>
  );
};


/**
 * CleaningPage component
 * 
 * This component is responsible for displaying a data grid and other buttons required to perform data cleaning operations for an in-field product.
 * The component fetches the data from the `dataset` backend APIs and displays it on a table. 
 * The contents of this component allow the user to perform the following operations required for data cleaning:
 * 1. View the data for a specific MAC address
 * 2. Run Automated Measurement Flagger which automatically marks measurements for invalidation based on a configured set of rules provided by the user (in `DataCleaning.jsx`).
 * 3. Invalidate / Revalidate the selected measurements.
 * 4. Re-run the middleware for the selected MAC address.
 * 5. Mark a MAC address as cleaned or uncleaned.
 * 6. Update the measurements of a product with any validity modifications made by the data cleaner. (This will make the product on the dashboard reflect any changes made).
 * 7. View the survey responses attached to a specific measurement and move them to another measurement if required.
 * 
 * All modifications made by the user are stored in the local storage and are persisted across page reloads.
 * Future developments should consider storing the progress state of a specific product in the backend to allow multiple users to tag team the data cleaning process.
 * 
 */
export default function CleaningPage() {
  const { datasetId } = useParams(); // Extract datasetId from URL parameters
  const location = useLocation(); // Access location to get state
  const productToClean = location.state?.productToClean; // Safely access the productToClean from state
  const dataset = location.state?.dataset;  // Access dataset from state
  const [socket, setSocket] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars 
  const [canRerunMiddleware, setCanRerunMiddleware] = useState(false); // state to enable re-run middleware button (not used curently)

  // States for the entries for a specific MAC address
  const [entriesForMac, setEntriesForMac] = useState([]); // Add state for entries for a specific MAC address
  const entriesForMacRef = useRef(entriesForMac); // Create a ref to store the entriesForMac state
  const dataFetchCompleteRef = useRef(false);
  const [macAddresses, setMacAddresses] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [totalMacs, setTotalMacs] = useState(null);
  const [cleanedMacs, setCleanedMacs] = useState(null);
  const [surveyMoveInProgress, setSurveyMoveInProgress] = useState([]);
  const surveyMoveInProgressRef = useRef(surveyMoveInProgress); // Create a ref to store the surveyMoveInProgress state

  // States for the confirmation dialogs, context menu, and snackbars
  const [isAutomatedFlaggingDialogOpen, setIsAutomatedFlaggingDialogOpen] = useState(false);
  const [isAutomatedFlaggingAllDialogOpen, setIsAutomatedFlaggingAllDialogOpen] = useState(false);
  const [isInvalidateDialogOpen, setIsInvalidateDialogOpen] = useState(false);
  const [isRerunMiddlewareDialogOpen, setIsRerunMiddlewareDialogOpen] = useState(false);
  const [isUpdateMeasurementsDialogOpen, setIsUpdateMeasurementsDialogOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, mac: null, showInvalidateAll: false });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [updateSnackbarOpen, setUpdateSnackbarOpen] = useState(false);
  const [updateSnackbarMessage, setUpdateSnackbarMessage] = useState('');
  const [updateSnackbarSeverity, setUpdateSnackbarSeverity] = useState('info');

  // LocalStorage Persistence States
  const [currentMacIndex, setCurrentMacIndex] = useState(() => JSON.parse(localStorage.getItem('currentMacIndex')) || 0);
  const previousMacIndexRef = useRef(currentMacIndex);
  const [hiddenColumns, setHiddenColumns] = useState(() => JSON.parse(localStorage.getItem('hiddenColumns')) || []);
  const [selectedRows, setSelectedRows] = useState(() => JSON.parse(localStorage.getItem('selectedRows')) || {});
  const selectedRowsRef = useRef(selectedRows); // Create a ref to store the selectedRows state
  const [rowValidationStatus, setRowValidationStatus] = useState(() => JSON.parse(localStorage.getItem('rowValidationStatus')) || {});
  const [validationStatus, setValidationStatus] = useState(() => JSON.parse(localStorage.getItem('validationStatus')) || {});
  const [rowExclusionFromMiddlewareStatus, setRowExclusionFromMiddlewareStatus] = useState(() => JSON.parse(localStorage.getItem('rowExclusionFromMiddlewareStatus')) || []);
  const [macStatus, setMacStatus] = useState(() => JSON.parse(localStorage.getItem('macStatus')) || {});
  const [flaggedEntries, setFlaggedEntries] = useState(() => JSON.parse(localStorage.getItem('flaggedEntries')) || {});
  const flaggedEntriesRef = useRef(flaggedEntries); // Create a ref to store the flaggedEntries state
  const criteriaColorMapRef = useRef({});

  // Popover and Menu states
  const { anchorEl: infoAnchorEl, handleOpen: handleInfoOpen, handleClose: handleInfoClose, isOpen: isInfoPopoverOpen, id: infoPopoverId } = usePopover();
  const { anchorEl: menuAnchorEl, handleOpen: handleMenuOpen, handleClose: handleMenuClose, isOpen: openMenu } = usePopover();
  const { anchorEl: criteriaKeyAnchorEl, handleOpen: handleCriteriaKeyOpen, handleClose: handleCriteriaKeyClose, isOpen: isCriteriaKeyOpen, id: criteriaKeyPopoverId } = usePopover();

  // Custom styles for the DataGrid rows based on their selection and validation status
  const classes = useStyles();

  // Access the history object to redirect the user back to the data cleaning page
  const history = useHistory();

  // Throttle progress update function to limit UI updates
  const updateProgress = _.throttle((imported, total) => {
    const progressPercent = (imported / total) * 100;
    setProgress(progressPercent);
    console.log(`Update progress: ${imported}/${total}`);
  }, 750); // Throttle to execute at most once every 750ms

  // Function to handle incoming WebSocket messages
  const onMessage = (data, socket) => {
    // Check for progress updates
    if (data.imported !== undefined && data.total !== undefined) {
      updateProgress(data.imported, data.total);
    }

    if (data.ok) {
      console.log('Operation completed successfully', data.message || '');
      // Perform any additional actions needed on completion
    } else {
      console.error('Operation failed', data.error || 'Unknown error');
      // Handle the error in UI
    }

    // Handle final completion message
    if (data.done) {
      if (data.ok) {
        console.log('Operation completed successfully', data.message || '');
        // Perform any additional actions needed on completion
      } else {
        console.error('Operation failed', data.error || 'Unknown error');
        // Handle the error in UI
      }
      setLoading(false);
    }
  };
  
  // Set up WebSocket connection in useEffect
  useEffect(() => {
    const connectSocket = async () => {
      try {
        const ws = await InternalApi.connect(onMessage);
        setSocket(ws);
        console.log('Connected to socket:', ws);
      } catch (error) {
        console.error('Failed to connect to socket:', error);
      }
    };

    connectSocket();

    return () => {
      if (socket) {
        InternalApi.disconnect(socket);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update the ref every time entriesForMac changes
  useEffect(() => {
    entriesForMacRef.current = entriesForMac;
  }, [entriesForMac]);

  // Update the ref every time surveyMoveInProgress changes
  useEffect(() => {
    surveyMoveInProgressRef.current = surveyMoveInProgress;
  }, [surveyMoveInProgress]);

  // Update the ref every time flaggedEntries changes
  useEffect(() => {
    console.log('Updated flaggedEntriesRef:', flaggedEntries);
    flaggedEntriesRef.current = flaggedEntries;
    console.log('flaggedEntriesRef.current:', flaggedEntriesRef.current);
    localStorage.setItem('flaggedEntries', JSON.stringify(flaggedEntries));
  }, [flaggedEntries]);

  /**
   * Handles context menu actions for a MAC address.
   * @param {Event} event - The right-click event triggering the context menu
   * @param {string} mac - The MAC address for which the context menu is triggered 
   */
  const handleContextMenu = (event, mac) => {
    event.preventDefault();
    const isCleaned = macStatus[mac]?.current === 'cleaned';
    const isSelectedMac = macAddresses[currentMacIndex] === mac;

    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      mac,
      isCleaned,
      showInvalidateAll: isSelectedMac,
    });
  };

  // Snackbar close handler
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Update Snackbar close handler
  const handleUpdateSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateSnackbarOpen(false);
  };

  // Handler to redirect the user back to the data cleaning page
  const handleBackToCleaning = () => {
      // Redirect back to the data cleaning URL
      setUpdateSnackbarOpen(false);
      history.push('/datacleaning'); // Adjust based on your routing setup
  };

  // Handler to handle moving survey responses from one entry to another
  const handleMoveSurveyResponse = (currentRowId, direction) => {
    setEntriesForMac(currentEntries => {
      let currentIndex = currentEntries.findIndex(entry => entry._id === currentRowId);
      if (currentIndex === -1) return currentEntries; // Current row not found
  
      // Exit early if there are no surveyResponses to move
      // Should not happen, but just in case
      if (!currentEntries[currentIndex].surveyResponses || currentEntries[currentIndex].surveyResponses.length === 0) {
        console.log("No surveyResponses found for this entry, exiting early.");
        console.log("currentEntries:", currentEntries);
        return currentEntries;
      }

      let targetIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
      // Loop to adjust targetIndex if target already has surveyResponses
      console.log("Target index:", targetIndex);
      while (targetIndex >= 0 && targetIndex < currentEntries.length && currentEntries[targetIndex].surveyResponses && currentEntries[targetIndex].surveyResponses.length > 0) {
        direction === 'up' ? targetIndex-- : targetIndex++;
        if (targetIndex < 0 || targetIndex >= currentEntries.length) {
          console.log("Could not find a suitable target index where surveyResponses can be moved.");
          return currentEntries;
        }
      }

      if (targetIndex < 0 || targetIndex >= currentEntries.length) {
        console.log("Target index out of bounds, exiting early.", targetIndex);
        console.log("currentEntries:", currentEntries);
        return currentEntries; // Boundary checks
      }

      // Proceed with the rest of the function as normal since surveyResponses exist
      const newEntries = [...currentEntries];
      const currentSurveyResponses = newEntries[currentIndex].surveyResponses;
      const targetMeasurementId = newEntries[targetIndex].measurement._id;

      // Update and set originalMeasurementId for surveyResponses
      const updatedSurveyResponses = currentSurveyResponses.map(response => {
        const originalMeasurementId = response.originalMeasurementId || response.measurementId;
        return {
          ...response,
          measurementId: targetMeasurementId, // Update to new measurementId
          originalMeasurementId: originalMeasurementId, // Preserve originalMeasurementId
        };
      });
    
      // Remove surveyResponses from the current entry and assign them to the target
      newEntries[targetIndex].surveyResponses = updatedSurveyResponses;
      delete newEntries[currentIndex].surveyResponses;
  
      console.log(`Moving surveyResponses from index ${currentIndex} to ${targetIndex}, with updated originalMeasurementId for first move:`, newEntries);


      // Update the tracking of moves in progress
      setSurveyMoveInProgress(prev => {
        // console.log('previous surveyMoveInProgress:', prev);
        const updated = prev.filter(id => id !== currentRowId); // Remove current moving survey
        updated.push(newEntries[targetIndex]._id); // Add new target entry id
        // console.log('updated surveyMoveInProgress:', updated);
        return updated;
      });

      // // Update setSurveyMoveInProgress to track the currentRowId
      // // If the survey was being moved, update its ID to the target's ID
      // setSurveyMoveInProgress(prev => {
      //   // If the survey was being moved, update its ID to the target's ID
      //   const isBeingMoved = prev.includes(currentRowId);
      //   if (isBeingMoved) {
      //     return prev.map(id => id === currentRowId ? newEntries[targetIndex]._id : id);
      //   }
      //   return prev;
      // });
      // console.log('surveyMoveInProgress:', surveyMoveInProgress);
      return newEntries;
    });
  };

  const startMovingSurveyResponse = (currentRowId, direction) => {
    // Proceed to move the survey response as before
    handleMoveSurveyResponse(currentRowId, direction);
  };

  const confirmMovingSurveyResponse = async (currentRowId) => {
    console.log('Confirming move for surveyResponses:', currentRowId);

    // Access the most current entriesForMac directly from the ref
    const entries = entriesForMacRef.current;
    console.log('up to date entriesForMac:', entries);

    // Hit endpoint to move surveyResponses to currentRowId
    const targetEntryId = currentRowId;
    console.log('targetEntryId:', targetEntryId);

    // get the surveyResponses _id for currentRowId
    const surveyResponseIds = entries.find(entry => entry._id === currentRowId)?.surveyResponses?.map(sr => sr._id);
    console.log('surveyResponseIds:', surveyResponseIds);

    try {
      const response = await Networker.post({
        root: `dataset/${datasetId}/entries/survey/move`,
        body: { surveyResponseIds, targetEntryId }
      });

      if (response.status === 200) {
        if (response.text === 'No surveyResponses to move') {
          console.log('No surveyResponses to move');
          setSnackbarMessage('No surveyResponses to move');
          setSnackbarSeverity('info');
          setSnackbarOpen(true);
        } else {
          console.log('Moved surveyResponses:', response);
          setSnackbarMessage('Moved surveyResponses successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        }
      } else {
        console.error('Failed to move surveyResponses:', response.error);
        setSnackbarMessage('Failed to move surveyResponses');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Failed to move surveyResponses:', error);
      setSnackbarMessage('Failed to move surveyResponses');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    // setSurveyMoveInProgress(prev => prev.filter(id => id !== currentRowId));
    // // Find the surveyResponse ID(s) being confirmed
    // const surveyResponseIds = entriesForMac.find(entry => entry._id === currentRowId)?.surveyResponses?.map(sr => sr._id) || [];
    // console.log('surveyResponseIds:', surveyResponseIds);
    // Remove these IDs from surveyMoveInProgress
    // setSurveyMoveInProgress(prev => prev.filter(id => !surveyResponseIds.includes(id)));
  };

  /**
   * Toggles the cleaning status of a MAC address.
   * If the MAC address is marked as cleaned, it will be unmarked. If it is unmarked, it will be marked as cleaned.
   * The previous status is stored in the macStatus state.
   * The context menu is hidden after the action is performed.
   */
  const toggleMacCleaningStatus = (mac = contextMenu.mac) => {
    setMacStatus((prevStatus) => {
      const { current, previous } = prevStatus[mac];
      const newStatus = current === 'cleaned' ? previous : 'cleaned';
      return {
        ...prevStatus,
        [mac]: { current: newStatus, previous: current }
      };
    });
    setContextMenu({ visible: false, x: 0, y: 0, mac: null }); // Hide menu
  };

  // Re-render the DataGrid when the entiresForMac state changes (due to local UI updates)
  useEffect(() => {
    console.log('Updated isSurveyMoveInProgress:', surveyMoveInProgress);
     // Update the rows to trigger a re-render
     const updatedRows = entriesForMac.map(entry => {
      // Start with the basic structure for each row
      const row = {
        valid: entry.valid,
        ...flattenObject(entry.measurement),
        id: entry._id, // Use the entry ID as the row ID
      };
      // Conditionally add 'surveyResponses' if it exists on the entry
      if (entry.surveyResponses) {
        row.surveyResponses = entry.surveyResponses;
      }
      return row;
    });
    // console.log('updatedRows:', updatedRows);
    // Calculate rows based on current page and use flattened measurements
    const startIndex = currentPage * MAX_ROWS;
    const endIndex = startIndex + MAX_ROWS;
    setRows(updatedRows.slice(startIndex, endIndex));
  }, [entriesForMac, currentPage, surveyMoveInProgress]);

  // Close context menu when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (contextMenu.visible) {
        setContextMenu({ visible: false, x: 0, y: 0, mac: null });
      }
    };
  
    document.addEventListener('click', handleOutsideClick);
  
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [contextMenu.visible]);  

  // Fetches the labels and validation status for the dataset and sets the macAddresses state.
  useEffect(() => {
    const fetchLabels = async () => {
        try {
            console.log('fetchLabels datasetId:', datasetId);
            const labelsResponse = await Networker.get({
                root: `dataset/${datasetId}/labels`,
            });

            const validationResponse = await Networker.get({
                root: `dataset/${datasetId}/labels/validation`,
            });

            if (labelsResponse.body && validationResponse.body) {
                // Reset macStatus when datasetId changes
                setMacStatus({}); // Clear old macStatus

                console.log('Fetched labels:', labelsResponse.body);
                setMacAddresses(labelsResponse.body);

                console.log('Fetched validation status:', validationResponse.body);
                setValidationStatus(validationResponse.body);
            } else {
                console.error('Failed to fetch labels or validation status');
                setSnackbarMessage('Failed to fetch labels or validation status');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Failed to fetch labels or validation status:', error);
        }
    };

    fetchLabels();
  }, [datasetId]);

  // Merge persisted macStatus with macAddresses to provide the user with the progress of the data cleaning process
  useEffect(() => {
    // Check if macAddresses is not empty
    if (macAddresses.length > 0) {
      // Get persisted macStatus from localStorage
      const persistedMacStatus = JSON.parse(localStorage.getItem('macStatus')) || {};

      // Generate a new macStatus based on macAddresses and validationStatus (if it exists)
      const newMacStatus = macAddresses.reduce((acc, mac) => {
          const validationForMac = validationStatus?.find(v => v.mac === mac);
          const isMacValidated = validationForMac ? validationForMac.validated === true : false;
        
          return {
              ...acc,
              [mac]: persistedMacStatus[mac] || { 
                  current: isMacValidated ? 'validated' : 'default', 
                  previous: persistedMacStatus[mac]?.previous || 'default' 
              }
          };
      }, {});

      setMacStatus(newMacStatus);
    }
  }, [macAddresses, validationStatus]);

  // Fetches the entries for the current MAC address and updates the rows and columns states.
  const fetchEntriesForMac = async () => {
    if (macAddresses.length > 0) {
      const mac = macAddresses[currentMacIndex];
      try {
        const response = await Networker.get({
          root: `dataset/${datasetId}/entries`,
          query: { label: mac },
        });

        console.log('fetchEntriesForMac response:', response);

        if (response.body) {
          const entries = response.body;
          setEntriesForMac(entries);

          // This sets initial surveyMoveInProgress as the _id of the entry.surveyResponses
          // // Getting _id of all surveyResponses for invalid entries (Should only have 1 entry, but in case of multiple, we handle them all)
          // const initialSurveyMoveInProgress = entries
          //   .filter(entry => !entry.valid && entry.surveyResponses && entry.surveyResponses.length > 0)
          //   .flatMap(entry => entry.surveyResponses.map(sr => sr._id));

          // This sets initialSurveyMoveInProgress as the _id of the entry
          // After setting entries, determine which entries have survey responses attached to invalid rows
          const initialSurveyMoveInProgress = entries
            .filter(entry => !entry.valid && entry.surveyResponses && entry.surveyResponses.length > 0)
            .map(entry => entry._id); // Assuming entry._id is the correct identifier

          setSurveyMoveInProgress(initialSurveyMoveInProgress);
          console.log('initialSurveyMoveInProgress:', initialSurveyMoveInProgress);

          // Flatten each entry object and collect all unique keys
          const allKeys = new Set();
          const flattenedEntries = entries.map(entry => {
            const flattened = {
              valid: entry.valid,
              ...flattenObject(entry.measurement), // Flatten the measurement object
              entryId: entry._id, // preserving entry._id to pass to checkbox selection component
            };
            
            // Conditionally add 'surveyResponses' if it exists and is not empty
            if (entry.surveyResponses && entry.surveyResponses.length > 0) {
              // console.log('entry.surveyResponses:', entry.surveyResponses);
              flattened.surveyResponses = entry.surveyResponses;
            }

            // Add keys to the set
            Object.keys(flattened).forEach(key => allKeys.add(key));
            return flattened;
          });

          // Generate dynamic columns, sorting '_id' to the front
          const dynamicColumns = Array.from(allKeys)
            .sort((a, b) => {
              if (a === 'valid') return -1;
              if (b === 'valid') return 1;
              if (a === '_id') return -1;
              if (b === '_id') return 1;
              if (a === 'surveyResponses') return -1;
              if (b === 'surveyResponses') return 1;
              return a.localeCompare(b);
            })
            .reduce((acc, key) => {
              // Exclude the unique key for 'entry._id' from columns
              if (key !== 'entryId') {
                const column = {
                  field: key,
                  headerName: key,
                  width: key === '_id' ? 220 : 190, // Set width to 220 for '_id' and 190 for other columns
                  renderHeader: (params) => <CustomHeader columnInfo={params.colDef} />,
                  hide: hiddenColumns.includes(key),
                };

                // Handle special cases for specific boolean columns
                if (key === 'data.forced' || key === 'valid') {
                  column.type = 'boolean';
                  column.headerAlign = 'left'; // Align the header to the left to fix slight misalignment
                  column.align = 'left'; // Align the row values to the left for boolean columns

                  if (key === 'data.forced') {
                    // Custom rendering for 'data.forced' to display 'true' or 'false'
                    column.renderCell = (params) => params.value ? 'true' : 'false';
                  } else if (key === 'valid') {
                    column.width = 120;
                    column.renderCell = (params) => renderValidCell(params, flaggedEntriesRef, mac, criteriaColorMapRef);
                  }
                }

                // Special column width for 'measurementTimeUTC' and 'measurementTimeLocal' columns (if they exist)
                if (key === 'measurementTimeUTC' || key === 'measurementTimeLocal') {
                  column.width = 235;
                }

                // Special column width for 'surveyResponses' column (if it exists)
                if (key === 'surveyResponses') {
                  column.width = 150;
                  column.renderCell = (params) => RenderSurveyResponsesCellTest(params, params.row.valid, startMovingSurveyResponse, confirmMovingSurveyResponse, surveyMoveInProgressRef);
                }

                acc.push(column);
              }
              return acc;
            }, []);
          setColumns(dynamicColumns);

          // Calculate rows based on current page and use flattened measurements
          const startIndex = currentPage * MAX_ROWS;
          const endIndex = startIndex + MAX_ROWS;

          // Generate rows from the flattened entries
          const dynamicRows = flattenedEntries.slice(startIndex, endIndex).map(entry => ({
            id: entry.entryId, // refers to entry._id included above
            ...entry, 
          }));
          setRows(dynamicRows);

          // Retrieve selectedRows and flaggedEntries for the current MAC address from localStorage
          const currentSelectedRowsRef = selectedRowsRef.current;
          // console.log('currentSelectedRowsRef fetchEntriesForMac:', currentSelectedRowsRef);
          const savedSelectedRows = currentSelectedRowsRef[mac] || [];
          // console.log('savedSelectedRows fetchEntriesForMac:', savedSelectedRows);

          setSelectedRows(prevSelectedRows => {
            const newSelectedRows = {
              ...prevSelectedRows,
              [mac]: savedSelectedRows,
            };
            selectedRowsRef.current = newSelectedRows;
            return newSelectedRows;
          });
        } else {
          console.error('Failed to fetch entries for MAC:', response.error);
        }
      } catch (error) {
        console.error('Failed to fetch entries for MAC:', error);
      }
    }
  };

  // Effect to fetch entries and populate the data grid of the current MAC address when the currentMacIndex changes
  useEffect(() => {
    const fetchData = async () => {
      await fetchEntriesForMac();
      dataFetchCompleteRef.current = true; // Set to true when data fetch completes

      // Scroll to the top
      window.scrollTo({ top: 0, behavior: 'auto' });
    };
  
    dataFetchCompleteRef.current = false; // Reset before starting a new fetch
    fetchData();
    // fetchEntriesForMac();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetId, currentMacIndex, currentPage, macAddresses]); // This runs when currentMacIndex, currentPage, or macAddresses changes
  
  // Handles updating the invalidated and revalidated entries in the db based on the selected rows
  const handleInvalidate = async () => {
    const label = macAddresses[currentMacIndex];
    // Prepare requestBody with empty arrays for invalidate and validate
    const requestBody = { invalidate: [], validate: [] };
  
    // Go through each selectedRow ID and find the corresponding entry
    selectedRows[label]?.forEach(rowId => {
      const entry = entriesForMac.find(entry => entry._id === rowId);
      if (entry) {
        if (entry.valid) {
          requestBody.invalidate.push(rowId); // If entry is valid, add to invalidate list
        } else {
          requestBody.validate.push(rowId); // If entry is invalid, add to validate list
        }
      }
    });
  
    if (requestBody.invalidate.length > 0 || requestBody.validate.length > 0) {
      try {
        const response = await Networker.post({
          root: `dataset/${datasetId}/entries/update`,
          body: requestBody,
        });
  
        if (response.status === 200) {
          console.log('Entries updated successfully');

          // Update the rowValidationStatus state for the current MAC address
          setRowValidationStatus(prevStatus => {
            const updatedStatusForCurrentMac = { ...prevStatus[label] };
            requestBody.invalidate.forEach(id => updatedStatusForCurrentMac[id] = false); // Invalidate
            requestBody.validate.forEach(id => updatedStatusForCurrentMac[id] = true); // Revalidate

            return {
              ...prevStatus,
              [label]: updatedStatusForCurrentMac,
            };
          });

          // Update the entriesForMac state to reflect the changes
          const updatedEntries = entriesForMac.map(entry => {
            if (requestBody.invalidate.includes(entry._id)) {
                return { ...entry, valid: false }; // Invalidate entry
            } else if (requestBody.validate.includes(entry._id)) {
                return { ...entry, valid: true }; // Validate entry
            }
            return entry; // Return unchanged entry
          });
          setEntriesForMac(updatedEntries); // Update state with modified entries

          // Now, update the rows based on the updatedEntries
          const updatedRows = updatedEntries.map((entry) => ({
              ...flattenObject(entry.measurement),
              valid: entry.valid,
              id: entry._id,  // Use the entry ID as the row ID
              surveyResponses: entry.surveyResponses,
          }));
          setRows(updatedRows.slice(currentPage * MAX_ROWS, (currentPage + 1) * MAX_ROWS)); // Update rows for the current page

          setSelectedRows(prevSelectedRows => ({
            ...prevSelectedRows,
            [label]: [],
          }));
          setCanRerunMiddleware(true); // Allow middleware to be re-run
        } else {
          console.error('Failed to update entries:', response.statusText);
          // Handle error, show error message to user if needed
        }
      } catch (error) {
        console.error('Error updating entries:', error);
        // Handle error, show error message to user if needed
      }
    }
  };

  // Handle Invalidate All context menu option for the currently selected MAC address
  const handleInvalidateAll = async () => {
    const label = macAddresses[currentMacIndex];

    // Filter all valid entries for this MAC
    const validEntries = entriesForMac.filter(entry => entry.valid === true);
    const requestBody = {
      invalidate: validEntries.map(entry => entry._id),
      validate: [],
    };

    try {
      const response = await Networker.post({
        root: `dataset/${datasetId}/entries/update`,
        body: requestBody,
      });

      if (response.status === 200) {
        console.log('All valid entries invalidated successfully');

        // Update UI states
        // Update the rowValidationStatus state for the current MAC address
        setRowValidationStatus(prevStatus => {
          const updatedStatusForCurrentMac = { ...prevStatus[label] };
          requestBody.invalidate.forEach(id => updatedStatusForCurrentMac[id] = false); // Invalidate
          requestBody.validate.forEach(id => updatedStatusForCurrentMac[id] = true); // Revalidate

          return {
            ...prevStatus,
            [label]: updatedStatusForCurrentMac,
          };
        });

        // Update the entriesForMac state to reflect the changes
        const updatedEntries = entriesForMac.map(entry => {
          if (requestBody.invalidate.includes(entry._id)) {
              return { ...entry, valid: false }; // Invalidate entry
          } else if (requestBody.validate.includes(entry._id)) {
              return { ...entry, valid: true }; // Validate entry
          }
          return entry; // Return unchanged entry
        });

        setEntriesForMac(updatedEntries);

        // Update the rows to reflect the changes
        const updatedRows = updatedEntries.map((entry) => ({
          ...flattenObject(entry.measurement),
          valid: entry.valid,
          id: entry._id,
          surveyResponses: entry.surveyResponses,
        }));

        setRows(updatedRows.slice(currentPage * MAX_ROWS, (currentPage + 1) * MAX_ROWS));
      } else {
        console.error('Failed to update entries:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating entries:', error);
    }
  };

  // Handle page change for a specific MAC address with more than MAX_ROWS entries
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  
  // Handle MAC address change when the user clicks on a different MAC address
  const handleMacChange = (newMacIndex) => {
    previousMacIndexRef.current = currentMacIndex;
    setCurrentMacIndex(newMacIndex);
    setCurrentPage(0); // Reset page when changing MAC address
  };

  // Effect to handle MAC address change and cleaned status via keyboard navigation (ArrowRight and ArrowLeft keys or '1' key)
  useEffect(() => {
    const handleKeyDown = (event) => {
      let newIndex = currentMacIndex;
      if (event.key === 'ArrowRight') {
        newIndex = (currentMacIndex + 1) % macAddresses.length;
      } else if (event.key === 'ArrowLeft') {
        newIndex = (currentMacIndex - 1 + macAddresses.length) % macAddresses.length;
      } else if (event.key === '1' ) {
        toggleMacCleaningStatus(macAddresses[currentMacIndex]);
      }

      if (newIndex !== currentMacIndex) {
        handleMacChange(newIndex);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMacIndex, macAddresses]);

  const handleAutomatedFlagging = async (macAddress = null) => {
    const label = macAddress || macAddresses[currentMacIndex]; // Use the passed MAC address or the current MAC index

    // Read queries from dataset.rules instead of hardcoding them
    const filterQueries = dataset.rules?.filter || [];
    const orderQueries = dataset.rules?.order || {};
    const outlierQueries = dataset.rules?.outlier || {};

    console.log('Dataset configuration rules:', dataset.rules);

    try {
      // Map each query to a promise for a network request, tagging each ID with the query
      const filterPromises = filterQueries.map((query, index) =>
        Networker.post({
          root: `measurements/label/${label}/filter`,
          body: query,
        }).then(response => {
          console.log('Filter query response:', response.body);
          return response.body && response.body.length > 0 ? response.body.map(id => ({ id, queryIndex: index })) : [];
        })
      );

      // Promise for the order endpoint
      const orderIndex = filterQueries.length; // Use the next index after the last filter query
      const orderPromise = Networker.post({
        root: `measurements/label/${label}/filter/order`,
        query: productToClean ? { productId: productToClean._id } : {},
        body: orderQueries,
      }).then(response => {
        // console.log('Order query response:', response.body);
        return response.body && response.body.length > 0 ? response.body.map(item => ({ id: item.id, criteria: [item.criteria], queryIndex: orderIndex })) : [];
      });

      const outlierIndex = orderIndex + 1;
      const outlierPromise = Networker.post({
        root: `measurements/label/${label}/filter/outlier`,
        query: productToClean ? { productId: productToClean._id } : {},
        body: outlierQueries,
      }).then(response => {
        // console.log('Outlier query response:', response.body);
        return response.body && response.body.length > 0 ? response.body.map(item => ({ id: item.id, criteria: item.criteria, queryIndex: outlierIndex })) : [];
      });
  
      // Wait for all promises to resolve
      const results = await Promise.all([...filterPromises, orderPromise, outlierPromise]);
      // console.log('Automated flagging results:', results);
  
      // Flatten the results and remove duplicates if needed
      const allFlaggedItems = results.flat();
      // console.log('All flagged items:', allFlaggedItems);

      const flaggedEntriesMap = allFlaggedItems.reduce((acc, item) => {
        if (!acc[item.id]) {
          acc[item.id] = [];
        }
        acc[item.id].push(item);
        return acc;
      }, {});
      // console.log('Flagged entries map:', flaggedEntriesMap);
    
      if (Object.keys(flaggedEntriesMap).length > 0) {
        let newFlaggedEntries = { ...flaggedEntries };
        let newSelectedRows = { ...selectedRowsRef.current }; // Temporary object for selected rows
    
        // Clear the entries for the specific label
        newFlaggedEntries[label] = {};
    
        // Get the entries for the current MAC address
        const entriesForMacCurrent = entriesForMacRef.current;
    
        Object.entries(flaggedEntriesMap).forEach(([id, items]) => {
          const entry = entriesForMacCurrent.find(entry => entry.measurement._id === id);
          if (entry) {
            let accumulatedCriteria = [];
            items.forEach(item => {
              if (item.queryIndex === orderIndex || item.queryIndex === outlierIndex) {
                accumulatedCriteria = [...accumulatedCriteria, ...item.criteria];
              } else {
                accumulatedCriteria.push(filterQueries[item.queryIndex]);
              }
            });
            // console.log(`Row with ID ${entry._id} selected due to criteria:`, accumulatedCriteria);
    
            // Collect selected rows in the temporary object (to update once after iterating through)
            newSelectedRows[label] = [...new Set([...(newSelectedRows[label] || []), entry._id])];
    
            // Accumulate criteria for the flagged entry
            if (!newFlaggedEntries[label][entry._id]) {
              newFlaggedEntries[label][entry._id] = [];
            }
            newFlaggedEntries[label][entry._id] = [...new Set([...newFlaggedEntries[label][entry._id], ...accumulatedCriteria])];
    
            // Construct a description of the criteria for the flagged entry
            const criteriaDescription = JSON.stringify(newFlaggedEntries[label][entry._id]);
            newFlaggedEntries[label][entry._id] = criteriaDescription;
          }
        });
  
        // Update flaggedEntries state and ref after processing all entries
        setFlaggedEntries(prevFlaggedEntries => {
            const updatedFlaggedEntries = {
                ...prevFlaggedEntries,
                [label]: newFlaggedEntries[label] // Replace the entries for this label
            };
            flaggedEntriesRef.current = updatedFlaggedEntries;
            return updatedFlaggedEntries;
        });

        // Update selectedRows state and ref after processing all entries
        setSelectedRows(prevSelectedRows => {
          const updatedSelectedRows = {
            ...prevSelectedRows,
            [label]: [...new Set([...(prevSelectedRows[label] || []), ...newSelectedRows[label]])]
          };
          selectedRowsRef.current = updatedSelectedRows;
          return updatedSelectedRows;
        });

        const flaggedCount = Object.keys(newFlaggedEntries[label] || {}).length;

        // Update macStatus if any entries were flagged
        if (flaggedCount > 0) {
          setMacStatus(prevStatus => {
            const currentStatus = prevStatus[label]?.current || 'default';
            return {
              ...prevStatus,
              [label]: { current: 'flagged', previous: currentStatus }
            };
          });
        }

        setSnackbarMessage(flaggedCount === 1 ? 
          `${flaggedCount} measurement flagged and selected.` : 
          `${flaggedCount} measurements flagged and selected.`);
        setSnackbarSeverity('warning'); // Set the severity to "warning" for flagged measurements
        setSnackbarOpen(true);

        // Re-fetch entries to update the table
        // await fetchEntriesForMac();
        return flaggedCount;
      } else {
        setSnackbarMessage('No measurements flagged.');
        setSnackbarSeverity('info'); // Set the severity to "info" for no flagged measurements
        setSnackbarOpen(true);
        return 0;
      }
    } catch (error) {
      console.error('Error running automated flagging:', error);
      setSnackbarMessage('Error occurred while running automated flagging.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return 0;
    }
  };


  const handleAutomatedFlaggingForAllMacs = async () => {
    try {
      setLoading(true);
      let totalFlagged = 0; // Initialize total flagged count
  
      const processNextMac = async (index) => {
        if (index < macAddresses.length) {
          const macAddress = macAddresses[index];
          handleMacChange(macAddresses.indexOf(macAddress)); // Set the current MAC index to update entries
  
          // Wait for the data fetch to complete before proceeding
          await new Promise((resolve) => {
            const checkDataFetchComplete = () => {
              if (dataFetchCompleteRef.current) {
                resolve();
              } else {
                setTimeout(checkDataFetchComplete, 100); // Check every 100ms
              }
            };
            checkDataFetchComplete();
          });
  
          const flaggedCount = await handleAutomatedFlagging(macAddress);
          totalFlagged += flaggedCount; // Accumulate the flagged counts
  
          await processNextMac(index + 1); // Process the next MAC address
        } else {
          setLoading(false);
          setSnackbarMessage(totalFlagged === 1 ?
            `${totalFlagged} measurement flagged across all MAC addresses.` :
            `${totalFlagged} measurements flagged across all MAC addresses.`);
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        }
      };
  
      await processNextMac(0); // Start processing from the first MAC address
    } catch (error) {
      setLoading(false);
      console.error('Error running automated flagging for all MACs:', error);
      setSnackbarMessage('Error occurred while running automated flagging for all MAC addresses.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Handle required state updates and API calls when the user clicks the "Invalidate / Revalidate" button
  const handleInvalidateClick = () => {
    handleInvalidate(); // Call the existing handleInvalidate function
    const currentMac = macAddresses[currentMacIndex];
    setMacStatus((prevStatus) => {
      // Retrieve the current status for this MAC
      const { current } = prevStatus[currentMac];
      // Update the status to 'inProgress', keeping the previous status
      return {
        ...prevStatus,
        [currentMac]: { current: 'inProgress', previous: current }
      };
    });
    setCanRerunMiddleware(true); // Set canRerunMiddleware to true after invalidation
  };

  // Handle required state updates and API calls when the user clicks the "Invalidate All" button
  const handleInvalidateAllClick = () => {
    handleInvalidateAll(); // Call the existing handleInvalidateAll function
    const currentMac = macAddresses[currentMacIndex];
    setMacStatus((prevStatus) => {
      // Retrieve the current status for this MAC
      const { current } = prevStatus[currentMac];
      // Update the status to 'inProgress', keeping the previous status
      return {
        ...prevStatus,
        [currentMac]: { current: 'inProgress', previous: current }
      };
    });
    setCanRerunMiddleware(true); // Set canRerunMiddleware to true after invalidation
  }

  // Handle required state updates and API calls when the user clicks the "Re-run Middleware" button
  const handleRunMiddleware = async () => {
    setIsRerunMiddlewareDialogOpen(false); // Close dialog after confirmation
    const label = macAddresses[currentMacIndex];
    console.log('Running middleware for label:', label);
  
    try {
      setLoading(true);
      const response = await Networker.post({
        root: `dataset/${datasetId}/entries/predict`,
        query: { label },
      });

      console.log('Middleware run response:', response);

      if (response.status === 200) {
        setLoading(false);
        // Update the macStatus state to reflect the changes
        setMacStatus((prevStatus) => {
          const { current } = prevStatus[label];
          // Update the status to 'inProgress' if it is not already
          return {
            ...prevStatus,
            [label]: { current: 'inProgress', previous: current }
          };
        });

        // Re-fetch entries to update the table
        await fetchEntriesForMac();

        // Now that fetchEntriesForMac has completed, entriesForMac is up to date
        // Filter out entries where 'valid' is false
        const excludedEntries = entriesForMac.filter(entry => !entry.valid).map(entry => entry._id);
        console.log('Invalidated entries excluded from middleware re-run:', excludedEntries);

        // Update rowExclusionFromMiddlewareStatus with IDs of excluded entries
        setRowExclusionFromMiddlewareStatus(excludedEntries);
      } else {
        setLoading(false);
        console.error('Failed to re-run middleware:', response.statusText);
      }
    } catch (error) {
      setLoading(false);
      console.error('Network error:', error);
    }
  };  

  // Function to handle the update process using POST request
  // // Handle required state updates and API calls when the user clicks the "Update" button after finishing the cleaning process
  // const handleUpdate = async () => {
  //   console.log('Updating measurements for dataset:', datasetId);

  //   try {
  //     setLoading(true);
  //     const response = await Networker.post({
  //       root: `dataset/${datasetId}/export`,
  //     });

  //     if (response.status === 200) {
  //       // Get validation status of the macs
  //       const validationResponse = await Networker.get({
  //         root: `dataset/${datasetId}/labels/validation`,
  //       });

  //       if (validationResponse.body) {
  //         console.log('Fetched validation status:', validationResponse.body);

  //         // Update macStatus based on validation response
  //         setMacStatus((prevStatus) => {
  //           // Create a new macStatus object by iterating over the validationResponse
  //           const updatedMacStatus = validationResponse.body.reduce((acc, { mac, validated }) => {
  //             const currentMacStatus = prevStatus[mac] || { current: 'default', previous: 'default' };

  //             // Update the current and previous status based on the validation status
  //             return {
  //               ...acc,
  //               [mac]: {
  //                 current: validated ? 'validated' : 'default',
  //                 previous: currentMacStatus.current,
  //               },
  //             };
  //           }, {});

  //           // Merge with the previous state
  //           return {
  //             ...prevStatus,
  //             ...updatedMacStatus,
  //           };
  //         });

  //         // Clear all entries for the MACs in the rowValidationStatus state if validated
  //         setRowValidationStatus((prevRowValidationStatus) => {
  //           // Iterate over the validationResponse to clear all entries for validated MACs
  //           const updatedRowValidationStatus = validationResponse.body.reduce((acc, { mac, validated }) => {
  //             // If the MAC is validated, remove all entries for that MAC
  //             if (!validated) {
  //               // Keep the original MAC entries if not validated
  //               acc[mac] = prevRowValidationStatus[mac];
  //             }
  //             // If the MAC is validated, it won't be added to acc, meaning it will be removed from the new state
  //             return acc;
  //           }, {});

  //           // Return the updated rowValidationStatus
  //           return updatedRowValidationStatus;
  //         });

  //         setValidationStatus(validationResponse.body);
  //       } else {
  //         console.error('Failed to fetch validation status');
  //       }

  //       setLoading(false);
  //       console.log('Successfully updated measurements!')
  //       setUpdateSnackbarMessage('Successfully updated measurements.');
  //       setUpdateSnackbarSeverity('success');
  //     } else {
  //       setLoading(false);
  //       console.error('Failed to update measurements:', response.statusText);
  //       setUpdateSnackbarMessage('Failed to update measurements.');
  //       setUpdateSnackbarSeverity('error');
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error('Network error:', error);
  //     setUpdateSnackbarMessage('Network error.');
  //     setUpdateSnackbarSeverity('error');
  //   } finally {
  //     setUpdateSnackbarOpen(true);
  //   }
  // };

  // Function to handle the update process using WebSocket
  const handleUpdate = async () => {
    if (!socket) {
      console.error('WebSocket is not connected.');
      setUpdateSnackbarMessage('WebSocket is not connected. Refresh the page and try again.');
      setUpdateSnackbarSeverity('error');
      setUpdateSnackbarOpen(true);
      return;
    }
    
    console.log('Updating measurements for dataset:', datasetId);
    setLoading(true);

    try {
      // Send the WebSocket request to start the update
      const msg = {
        id: datasetId,
      };
      console.log('Sending update product data (export) request via WebSocket:', msg);

      const response = await InternalApi.request(socket, 'dataset/export', msg);

      if (response && response.ok) {
        // Get validation status of the macs
        const validationResponse = await Networker.get({
          root: `dataset/${datasetId}/labels/validation`,
        });
        
        if (validationResponse.body) {
          console.log('Fetched validation status:', validationResponse.body);

          // Update macStatus based on validation response
          setMacStatus((prevStatus) => {
            const updatedMacStatus = validationResponse.body.reduce((acc, { mac, validated }) => {
              const currentMacStatus = prevStatus[mac] || { current: 'default', previous: 'default' };

              return {
                ...acc,
                [mac]: {
                  current: validated ? 'validated' : 'default',
                  previous: currentMacStatus.current,
                },
              };
            }, {});

            return {
              ...prevStatus,
              ...updatedMacStatus,
            };
          });

          // Clear all entries for validated MACs in the rowValidationStatus state
          setRowValidationStatus((prevRowValidationStatus) => {
            const updatedRowValidationStatus = validationResponse.body.reduce((acc, { mac, validated }) => {
              if (!validated) {
                acc[mac] = prevRowValidationStatus[mac];
              }
              return acc;
            }, {});

            return updatedRowValidationStatus;
          });

          setValidationStatus(validationResponse.body);
        } else {
          console.error('Failed to fetch validation status');
        }

        setLoading(false);
        console.log('Successfully updated measurements!')
        setUpdateSnackbarMessage('Successfully updated measurements.');
        setUpdateSnackbarSeverity('success');
      } else {
        setLoading(false);
        console.error('Failed to update measurements:', response.statusText);
        setUpdateSnackbarMessage('Failed to update measurements.');
        setUpdateSnackbarSeverity('error');
      }
    } catch (error) {
      setLoading(false);
      console.error('Network error:', error);
      setUpdateSnackbarMessage('Network error.');
      setUpdateSnackbarSeverity('error');
    } finally {
      setUpdateSnackbarOpen(true);
    }
  };

  // Function to toggle the visibility of a column
  const toggleColumnVisibility = (columnField, isVisible) => {
    setHiddenColumns((prevHiddenColumns) => {
      const newHiddenColumns = new Set(prevHiddenColumns);
  
      if (isVisible) {
        newHiddenColumns.delete(columnField);
      } else {
        newHiddenColumns.add(columnField);
      }
  
      return Array.from(newHiddenColumns);
    });
  };  

  // Function to reset the status of MAC addresses to 'default' from 'inProgress' (for the case where any validation changes were un-done by the user)
  const resetMacStatus = () => {
    setMacStatus((currentStatus) => {
      const updatedStatus = { ...currentStatus };
      Object.keys(updatedStatus).forEach((mac) => {
        if (updatedStatus[mac].current === 'inProgress') {
          // Find the validation status for the current MAC address
          const validationForMac = validationStatus?.find(v => v.mac === mac);
          const isMacValidated = validationForMac ? validationForMac.validated === true : false;
          
          // Push the current 'inProgress' state to the 'previous' before resetting to 'default'
          updatedStatus[mac] = { 
            current: isMacValidated ? 'validated' : 'default',  // Set to 'validated' if true, otherwise 'default'
            previous: updatedStatus[mac].current // Set the previous state to the current one before the reset
          };
        }
      });
      console.log('Reset macStatus:', updatedStatus);
      return updatedStatus;
    });
  };  
  
  // LocalStorage Persistence Effects

  useEffect(() => {
    const savedFlaggedEntries = JSON.parse(localStorage.getItem('flaggedEntries')) || {};
    setFlaggedEntries(savedFlaggedEntries);
    console.log('Updated flaggedEntries to local storage:', savedFlaggedEntries);
    flaggedEntriesRef.current = savedFlaggedEntries;
  }, []);

  useEffect(() => {
    localStorage.setItem('currentMacIndex', JSON.stringify(currentMacIndex));
    console.log('Updated currentMacIndex to local storage:', currentMacIndex);
  }, [currentMacIndex]);

  useEffect(() => {
    localStorage.setItem('hiddenColumns', JSON.stringify(hiddenColumns));
    console.log('Updated hiddenColumns to local storage:', hiddenColumns);
  }, [hiddenColumns]);  

  useEffect(() => {
    localStorage.setItem('selectedRows', JSON.stringify(selectedRows));
    console.log('Updated selectedRows to local storage:', selectedRows);
    localStorage.setItem('selectedRows', JSON.stringify(selectedRowsRef.current));
    console.log('Updated selectedRowsRef to local storage:', selectedRowsRef.current);
  }, [selectedRows]);

  useEffect(() => {
    localStorage.setItem('rowValidationStatus', JSON.stringify(rowValidationStatus));
    console.log('Updated rowValidationStatus to local storage:', rowValidationStatus);
  }, [rowValidationStatus]);

  useEffect(() => {
    localStorage.setItem('validationStatus', JSON.stringify(validationStatus));
    console.log('Updated validationStatus to local storage:', validationStatus);
  }, [validationStatus]);

  useEffect(() => {
    localStorage.setItem('rowExclusionFromMiddlewareStatus', JSON.stringify(rowExclusionFromMiddlewareStatus));
    console.log('Updated rowExclusionFromMiddlewareStatus to local storage:', rowExclusionFromMiddlewareStatus);
  }, [rowExclusionFromMiddlewareStatus]);

  useEffect(() => {
    localStorage.setItem('macStatus', JSON.stringify(macStatus));
    console.log('Updated macStatus to local storage:', macStatus);

    // Calculate the number of MACs marked as "cleaned" or "validated" and the total number of MACs for the progress bar
    const completedMacsCount = Object.values(macStatus).filter(status => 
        status.current === 'cleaned' || status.current === 'validated'
    ).length; // Count MACs with either 'cleaned' or 'validated' status

    setCleanedMacs(completedMacsCount); // Update cleanedMacs count
    setTotalMacs(macAddresses.length); // Update totalMacs count
  }, [macStatus, macAddresses]);

  // Calculate the total height of the data grid based on the number of rows
  const totalRows = entriesForMac.length;
  const totalHeight = Math.min(totalRows * ROW_HEIGHT + HEADER_HEIGHT, MAX_ROWS * ROW_HEIGHT + HEADER_HEIGHT);

  /**
  * Renders the CleaningPage component.
  * 
  * Includes buttons for various actions (e.g., Invalidate/Revalidate, Run Automated Measurement Flagger, Re-run Middleware),
  * a DataGrid for displaying MAC address entries, and custom context menus for additional actions, and buttons for viewing the data for a specific MAC address.
  */
  return (
    <div style={{ height: totalHeight, width: '100%' }}>
      {/* Top Bar Container */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2px' }}>
        {/* Info Icon with Tooltip and Popover */}
        <div>
          <Tooltip title="How to Use">
            <IconButton onClick={handleInfoOpen}>
              <InfoIcon style={{ fontSize: "2.0rem" }} color="primary" aria-describedby={infoPopoverId} />
            </IconButton>
          </Tooltip>
          <Popover
            id={infoPopoverId}
            open={isInfoPopoverOpen}
            anchorEl={infoAnchorEl}
            onClose={handleInfoClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ maxWidth: '340px' }}>
              <Typography variant="h6" style={{ marginBottom: '0px' }} gutterBottom>How to Use</Typography>
              {/* Step-by-step instructions */}
              <List style={{ paddingLeft: 0, fontSize: '0.875rem', alignItems: 'flex-start' }}>
                {[
                  "Click on a MAC to view the list of measurements.",
                  "Review the validity of the measurements.",
                  "Select the checkboxes of rows you wish to invalidate or revalidate.",
                  "Click on the 'Invalidate / Revalidate' button.",
                  "Confirm the dialog to update the measurement(s) validity status.",
                  "Click on the 'Re-run Middleware' button to recompute any computed data fields on the modified list of measurements.",
                  "Confirm the dialog to run this action.",
                  "Review the updated values displayed on the table.",
                  "Once satisfied with the results, right-click on the currently selected label MAC address or press '1' to mark the data as cleaned.",
                  "Repeat this process for the remaining MACs until all have been cleaned.",
                  "Click on the 'Update Product Data' button to update the product data with the latest changes."
                ].map((text, index) => (
                  <ListItem 
                    key={index} 
                    variant="body2" 
                    style={{ 
                      display: 'list-item', 
                      listStyleType: 'decimal', 
                      listStylePosition: 'outside', 
                      marginLeft: '20px',
                      paddingLeft: '1px',
                      paddingBottom: '1px',
                      }}>
                    <Typography variant="body2" component="span">
                      {text}
                    </Typography>
                  </ListItem>
                ))}
              </List>

              {/* Color codes for the table and MAC address buttons */}
              <Typography variant="h6" style={{ marginTop: "16px" }} gutterBottom>Table Color Codes</Typography>
              <Box display="flex" alignItems="center" marginBottom="8px">
                <Box style={{ backgroundColor: rowColors.invalidatedRow, width: "20px", height: "20px", marginRight: "8px" }} />
                <Typography variant="body2">Invalidated (Ready for Middleware Re-run) or Modified after being validated</Typography>
              </Box>
              <Box display="flex" alignItems="center" marginBottom="8px">
                <Box style={{ backgroundColor: rowColors.excludedFromMiddlewareRow, width: "20px", height: "20px", marginRight: "8px" }} />
                <Typography variant="body2">Excluded from Middleware Recomputation</Typography>
              </Box>
              <Box display="flex" alignItems="center" marginBottom="8px">
                <Box style={{ backgroundColor: rowColors.validatedRow, width: "20px", height: "20px", marginRight: "8px" }} />
                <Typography variant="body2">Measurement has been validated</Typography>
              </Box>
              <Typography variant="h6" style={{ marginTop: "16px" }} gutterBottom>MAC Address Button Color Codes</Typography>
              <Box display="flex" alignItems="center" marginBottom="8px">
                <Box style={{ backgroundColor: rowColors.flaggedMacStatus, width: "20px", height: "20px", marginRight: "8px" }} />
                <Typography variant="body2" style={{ flex: 1 }}>Label contains entries have been flagged for review by the Automated Measurement Flagger</Typography>
              </Box>
              <Box display="flex" alignItems="center" marginBottom="8px">
                <Box style={{ backgroundColor: rowColors.inProgressMacStatus, width: "20px", height: "20px", marginRight: "8px" }} />
                <Typography variant="body2" style={{ flex: 1 }}>Changes have been made, cleaning process is in progress</Typography>
              </Box>
              <Box display="flex" alignItems="center" marginBottom="8px">
                <Box style={{ backgroundColor: rowColors.cleanedMacStatus, width: "20px", height: "20px", marginRight: "8px" }} />
                <Typography variant="body2" style={{ flex: 1 }}>Label entries have been reviewed and cleaned (if necessary)</Typography>
              </Box>
              <Box display="flex" alignItems="center" marginBottom="8px">
                <Box style={{ backgroundColor: rowColors.validatedMacStatus, width: "20px", height: "20px", marginRight: "8px" }} />
                <Typography variant="body2" style={{ flex: 1 }}>Label entries have been reviewed and validated</Typography>
              </Box>
            </Box>
          </Popover>
        </div>

        {/* Left section for product info */}
        {productToClean && productToClean.name && productToClean.productId && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Avatar
              alt={productToClean.name.substr(0, 3)}
              src={productToClean.iconURL}
              style={{ width: 50, height: 50 }} // Smaller size for the top bar
            />
            <div>
              <h4 style={{ margin: 0, fontSize: '1.0rem' }}>{productToClean.name}</h4>
              <p style={{ margin: 0, fontSize: '0.8rem' }}>{productToClean.productId}</p>
            </div>
          </div>
        )}

        {/* Progress Bar and Update Button */}
        {cleanedMacs !== null && totalMacs > 0 && (
          <Box display="flex" alignItems="center" flex={1} justifyContent="center" mx={2}>
            <Box display="flex" alignItems="center" width="50%" mr={1}>
              <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'nowrap' }}>
                {`${cleanedMacs} / ${totalMacs}`}
              </Typography>
              <LinearProgress variant="determinate" value={(cleanedMacs / totalMacs) * 100} style={{ width: '100%', marginLeft: '8px', marginRight: '8px' }} />
              <Typography variant="body2" color="textSecondary">
                {`${Math.round((cleanedMacs / totalMacs) * 100)}%`}
              </Typography>
            </Box>
            {cleanedMacs === totalMacs && (
              <Button 
                variant="contained" 
                onClick={() => setIsUpdateMeasurementsDialogOpen(true)}
                style={{ 
                  backgroundColor: 'green', 
                  color: 'white', 
                  marginLeft: '8px',
                  whiteSpace: 'nowrap', // Prevent text wrapping
                  height: 36, // Set a fixed height to match other Material-UI buttons
                  minWidth: 200, // Set a fixed width to match other Material-UI buttons
                }}>
                Update Product Data
              </Button>
            )}
              {/* Confirmation Dialog for "Update Product Data" */}
              <ConfirmationDialog
                isOpen={isUpdateMeasurementsDialogOpen}
                onClose={() => setIsUpdateMeasurementsDialogOpen(false)}
                onConfirm={() => {
                  setIsUpdateMeasurementsDialogOpen(false); // Close dialog after confirmation
                  handleUpdate();
                }}
                title="Confirm Action"
                content="This action will update the product data with the latest changes. Are you sure you want to proceed? This may take some time."
              />
          </Box>
        )}

        {/* Key Icon and Criteria Color Key Popover */}
        <div>
          <IconButton onClick={handleCriteriaKeyOpen} disabled={Object.keys(flaggedEntriesRef.current).length === 0}>
            <VpnKeyIcon />
          </IconButton>
          <Popover
            id={criteriaKeyPopoverId}
            open={isCriteriaKeyOpen}
            anchorEl={criteriaKeyAnchorEl}
            onClose={handleCriteriaKeyClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            disableEnforceFocus
            disableScrollLock
          >
            <CriteriaColorKey
            criteriaColorMapRef={criteriaColorMapRef}
            rows={rows}
            setSelectedRows={setSelectedRows}
            selectedRowsRef={selectedRowsRef}
            currentMacIndex={currentMacIndex}
            macAddresses={macAddresses}
            flaggedEntriesRef={flaggedEntriesRef}
          />
          </Popover>
        </div>

        {/* List of Action Buttons (e.g., Invalidate/Revalidate, Run Automated Measurement Flagger, Re-run Middleware) */}
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsAutomatedFlaggingDialogOpen(true)} // Open dialog on button click
            // disabled={flaggedEntries[macAddresses[currentMacIndex]] && Object.keys(flaggedEntries[macAddresses[currentMacIndex]]).length > 0} // Disable if flagged entries exist for the current MAC
            style={{ marginRight: '10px' }}
          >
            Run Automated Measurement Flagger
          </Button>
          {/* Confirmation Dialog for "Run Automated Measurement Flagger" */}
          <ConfirmationDialog
            isOpen={isAutomatedFlaggingDialogOpen}
            onClose={() => setIsAutomatedFlaggingDialogOpen(false)}
            onConfirm={() => {
              console.log("Running Automated Measurement Flagger");
              setIsAutomatedFlaggingDialogOpen(false); // Close dialog after confirmation
              handleAutomatedFlagging();
            }}
            title="Confirm Action"
            content="This action will flag measurements for invalidation automatically based on the configured rules. 
            Are you sure you want to proceed?"
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsInvalidateDialogOpen(true)} // Open dialog on button click
            disabled={selectedRows[macAddresses[currentMacIndex]]?.length === 0} // Disable if no rows are selected for the current MAC
            style={{ marginRight: '10px' }}
          >
            Invalidate / Revalidate
          </Button>
          <ConfirmationDialog
            isOpen={isInvalidateDialogOpen}
            onClose={() => setIsInvalidateDialogOpen(false)}
            onConfirm={() => {
              setIsInvalidateDialogOpen(false); // Close dialog after confirmation
              handleInvalidateClick(); // Call the handleInvalidateClick function
            }}
            title="Confirm Action"
            content="Are you sure you want to invalidate / revalidate the selected measurement(s)? This action cannot be undone (unless re-run)."
          />

          <Button
            variant="contained"
            onClick={() => setIsRerunMiddlewareDialogOpen(true)} // Open dialog on button click
            disabled={selectedRows[macAddresses[currentMacIndex]]?.length > 0} // Disable if any rows are selected for the current MAC
            // disabled={!canRerunMiddleware} // Disabled until "Invalidate" has been clicked
          >
            Re-run Middleware
          </Button>
          <ConfirmationDialog
            isOpen={isRerunMiddlewareDialogOpen}
            onClose={() => setIsRerunMiddlewareDialogOpen(false)}
            onConfirm={handleRunMiddleware}
            title="Confirm Action"
            content="Are you sure you want to re-run the middleware for the selected mac? This action cannot be undone."
          />

          {/* MoreVertIcon with Menu */}
          <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleMenuOpen}>
            <MoreVertIcon /> {/* Use MoreHorizIcon for horizontal ellipsis */}
          </IconButton>
          <Menu id="menu" anchorEl={menuAnchorEl} keepMounted open={openMenu} onClose={handleMenuClose}>
            <MenuItem onClick={() => {
              setIsAutomatedFlaggingAllDialogOpen(true);
              handleMenuClose();
            }}>
              Run Automated Measurement Flagger on all MACs (BETA)
            </MenuItem>
            <MenuItem onClick={() => {
              // Clear flagged entries and flagged macStatuses & reset local storage
              localStorage.removeItem('flaggedEntries'); // Clear flagged entries and color map key from local storage
              flaggedEntriesRef.current = {}; // Clear flagged entries from the ref
              setFlaggedEntries({}); // Clear flagged entries from the state
              criteriaColorMapRef.current = {}; // Clear the criteria color map ref

              // Clear the selected rows for current MAC address
              setSelectedRows((prevSelectedRows) => {
                const updatedSelectedRows = { ...prevSelectedRows };
                delete updatedSelectedRows[macAddresses[currentMacIndex]];
                selectedRowsRef.current = updatedSelectedRows;
                return updatedSelectedRows;
              });

              // Reset the MAC status for all MACs that are flagged
              setMacStatus((prevStatus) => {
                const updatedStatus = { ...prevStatus };

                Object.keys(updatedStatus).forEach((mac) => {
                  if (updatedStatus[mac].current === 'flagged') {
                    // Reset current status to the previous status
                    updatedStatus[mac] = {
                      current: updatedStatus[mac].previous || 'default', // Set to previous or 'default'
                      previous: 'default' // Set the previous to 'default'
                    };
                  }
                });

                console.log('Updated macStatus after clearing flagged entries:', updatedStatus);
                return updatedStatus;
              });

              handleMenuClose();
            }}>
              Clear Flagged Entries
            </MenuItem>
            <MenuItem onClick={() => {
              localStorage.removeItem('selectedRows'); // Clear selected rows from local storage
              selectedRowsRef.current = {}; // Clear selected rows from the ref
              setSelectedRows({}); // Clear selected rows from the state
              handleMenuClose();
            }}>
              Clear All Selected Rows
            </MenuItem>
            <MenuItem onClick={() => {
              // Implement your reset functionality here
              resetMacStatus();
              handleMenuClose();
            }}>
              Reset In-Progress MACs
            </MenuItem>
            {/* Add more menu items as needed */}
          </Menu>

          {/* Confirmation Dialog for "Run Automated Measurement Flagger on all MACs" */}
          <ConfirmationDialog
            isOpen={isAutomatedFlaggingAllDialogOpen}
            onClose={() => setIsAutomatedFlaggingAllDialogOpen(false)}
            onConfirm={() => {
              console.log("Running Automated Measurement Flagger on all MACs");
              setIsAutomatedFlaggingAllDialogOpen(false); // Close dialog after confirmation
              handleAutomatedFlaggingForAllMacs();
            }}
            title="Confirm Action"
            content="This action will flag measurements for invalidation on all MAC addresses automatically based on the configured rules. 
            Are you sure you want to proceed?"
          />
        </div>
      </div>

      {/* Buttons for viewing the data for a specific MAC address */}
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(125px, 1fr))', gap: '2px', paddingBottom: '2px' }}>
        {macAddresses.map((mac, index) => (
          <button 
          key={`${mac}-${macStatus[mac]?.current}`}
          onContextMenu={(e) => handleContextMenu(e, mac)} 
          onClick={() => handleMacChange(index)}
          disabled={currentMacIndex === index}
          style={{
            backgroundColor: macStatus[mac]?.current === 'validated' ? 'rgba(173, 216, 230, 0.6)' : // light blue
                            macStatus[mac]?.current === 'cleaned' ? 'rgba(144, 238, 144, 0.6)' :
                            macStatus[mac]?.current === 'inProgress' ? 'rgba(255, 165, 0, 0.4)' :
                            macStatus[mac]?.current === 'flagged' ? 'rgba(255, 99, 71, 0.6)' : // light red
                            'default',
            cursor: 'pointer',
          }}>
          {mac}
          </button>
        ))}
      </div>
      {/* DataGrid for displaying MAC address entries */}
      <DataGrid
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[MAX_ROWS]}
        pageSize={MAX_ROWS}
        paginationMode="server"        
        onPageChange={handlePageChange}
        page={currentPage}
        rowCount={totalRows}
        pagination
        onSelectionModelChange={(newSelection) => {
          // Check if newSelection is different from current selection
          const currentSelection = selectedRows[macAddresses[currentMacIndex]] || [];
          if (newSelection.length === 0 && currentMacIndex !== previousMacIndexRef.current) {
            // setSelectedRows to what is stored in local storage
            setSelectedRows(JSON.parse(localStorage.getItem('selectedRows')));
            // Update previousMacIndexRef so now when user deselects all rows, the selected rows will update
            previousMacIndexRef.current = currentMacIndex;
            return;
          }
          if (JSON.stringify(newSelection) !== JSON.stringify(currentSelection) || newSelection.length === 0) {
            setSelectedRows((prevSelectedRows) => {
              const newSelectedRows = {
                ...prevSelectedRows,
                [macAddresses[currentMacIndex]]: newSelection,
              };
              selectedRowsRef.current = newSelectedRows;
              localStorage.setItem('selectedRows', JSON.stringify(newSelectedRows));
              previousMacIndexRef.current = currentMacIndex; // Update previousMacIndexRef after setting selected rows
              return newSelectedRows;
            });
          }
        }}
        checkboxSelection
        selectionModel={selectedRows[macAddresses[currentMacIndex]] || []}
        disableSelectionOnClick
        rowHeight={ROW_HEIGHT}
        autoHeight
        components={{
          Toolbar: GridToolbar,
        }}
        density="compact"
        onColumnVisibilityChange={(params) => {
          toggleColumnVisibility(params.field, params.isVisible);
        }}
        getRowClassName={(params) => {
          // Find the corresponding entry in entriesForMac
          const entry = entriesForMac.find(entry => entry._id === params.id);
          const isSelected = selectedRows[macAddresses[currentMacIndex]]?.includes(params.id);
          const isInvalidated = rowValidationStatus[macAddresses[currentMacIndex]]?.[params.id] === false; // Invalidated rows
          const isExcludedFromMiddlewareReRun = rowExclusionFromMiddlewareStatus.includes(params.id);
          const isValidated = entry?.validated === true; // Check if the entry's validated field is true
          const isModifiedAfterValidation = rowValidationStatus[macAddresses[currentMacIndex]]?.[params.id] === true && isValidated; // Modified after validation

          if (isSelected && isExcludedFromMiddlewareReRun) {
            return classes.selectedExcludedFromMiddlewareRow; // Light red for rows excluded from middleware re-run
          } else if (isSelected && isInvalidated) {
            return classes.selectedInvalidatedRow; // Darker orange for selected invalidated rows
          } else if (isSelected && isModifiedAfterValidation) {
            return classes.selectedInvalidatedRow; // Case where the row was modified after being validated
          } else if (isSelected && isValidated) {
            return classes.selectedValidatedRow; // Darker blue for selected validated rows
          } else if (isSelected) {
            return classes.selectedRow; // Light grey for selected valid rows
          } else if (isExcludedFromMiddlewareReRun) {
            return classes.excludedFromMiddlewareRow; // Light red for rows excluded from middleware re-run
          } else if (isInvalidated) {
            return classes.invalidatedRow; // Light orange for invalidated rows
          } else if (isModifiedAfterValidation) {
            return classes.invalidatedRow; // Case where the row was modified after being validated
          } else if (isValidated) {
            return classes.validatedRow; // Light green for validated rows
          }
          return '';
        }}
      />
      {/* Loader to show when the data is being fetched */}
      <Backdrop
        open={loading}
        style={{ zIndex: 1301, color: '#fff' }} // Apply styles directly
      >
        <Loader show={loading} progress={progress} />
      </Backdrop>
      {/* Context menu for marking a MAC address as cleaned or uncleaned */}
      {contextMenu.visible && (
      <div
        style={{
          position: 'absolute',
          top: contextMenu.y,
          left: contextMenu.x,
          backgroundColor: 'white',
          border: '1px solid black',
          zIndex: 1000,
        }}
      >
        <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
          <li onClick={() => toggleMacCleaningStatus(contextMenu.mac)} style={{ cursor: 'pointer', padding: '5px' }}>
            {macStatus[contextMenu.mac]?.current === 'cleaned' ? 'Unmark as Cleaned' : 'Mark as Cleaned'}
          </li>
          {contextMenu.showInvalidateAll && (
            <>
              {/* Conditional Divider */}
              <hr style={{ margin: '2px 0', border: 'none', borderTop: '1px solid #ccc' }} />
              <li
                onClick={() => handleInvalidateAllClick()}
                style={{ cursor: 'pointer', padding: '5px' }}
              >
                Invalidate All
              </li>
            </>
          )}
        </ul>
      </div>
      )}
      <div style={{ paddingTop: '50px' }}> {/* Adjust the paddingTop value as needed */}
        <MadeWithLove />
      </div>

      {/* Snackbar for alerting the user about the number of flagged measurements */}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbarSeverity} 
          style={{
            backgroundColor: snackbarSeverity === 'warning' ? '#FF8C00' : '', // Apply slightly darker orange color for "warning" severity
            fontSize: '1rem',
          }}
          elevation={6} variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Another Snackbar for alerting the user on the outcome of the "Update Product Data" action */}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={updateSnackbarOpen}
        onClose={handleUpdateSnackbarClose}
        >
        <Alert
          onClose={handleUpdateSnackbarClose}
          severity={updateSnackbarSeverity}
          action={
            <>
              {updateSnackbarSeverity === 'success' && (
                <>
                  <Button color="inherit" size="medium" onClick={handleBackToCleaning}>
                    Return to Data Cleaning Homepage
                  </Button>
                  <span style={{ marginRight: 5 }}></span>
                </>
              )}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleUpdateSnackbarClose}>
                <CloseIcon fontSize="medium" />
              </IconButton>
            </>
          }
          style={{
            fontSize: '1rem',
          }}
          elevation={6} variant="filled">
          {updateSnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}